import {CollectionPoint} from "../../../data/firebase/CollectionPointCollection";
import StatusText from "../map/StatusText";
import CollectionPointStatusActionButton from "./CollectionPointStatusActionButton";
import {Nicknames} from "../map/Nicknames";
import {Popup} from "react-leaflet";

const PopupBody = ({feature}: { feature: CollectionPoint }) => {
    return <>
        <StatusText feature={feature}/>
        <Nicknames feature={feature}/>
        <CollectionPointStatusActionButton collectionPoint={feature} modifier="quiet" details={true}/>
    </>
}
const CollectionPointPopup = ({collectionPoint}: { collectionPoint: CollectionPoint }) => (
    <Popup minWidth={230}>
        <PopupBody feature={collectionPoint}/>
    </Popup>
);

export default CollectionPointPopup
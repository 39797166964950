import {Input, List, ListHeader, ListItem, ListTitle, Page, Switch} from "react-onsenui";
import {Area, useAreas, useAreaStatusHandler} from "../../data/firebase/AreaCollection";
import {PropsWithChildren, useCallback, useMemo, useState} from "react";
import {Nicknames} from "../feature/map/Nicknames";
import {ResultStatus} from "../../data/firebase/Database";
import LoadingPage from "./LoadingPage";
import {AreaMap} from "../feature/area/AreaMap";
import {AreaCollectorsList} from "../feature/area/AreaCollectorsList";
import {FeatureStatus} from "../feature/map/StatusText";
import {R} from "../translation/i18n";
import {useT} from "../translation/Translate";
import {DesktopColumnLayout} from "../layout/DesktopColumnLayout";

const AreaDetails = ({area}: { area?: Area }) => {
    const {setStatus, setContact} = useAreaStatusHandler();
    const t = useT();

    const setAssigned = useCallback(async (ready: boolean) => {
        if (area) {
            await setStatus(area.id, ready ? FeatureStatus.AssignedForPrepare : FeatureStatus.Planned)
        }
    }, [area, setStatus]);

    if (!area) {
        return null
    }

    return (
        <Page>
            <div style={{padding: "0 16px"}}>
                <h1>Gebiet {area.properties.number}: {area.properties.name}</h1>
            </div>
            <List>
                <ListItem>
                    <AreaMap area={area}/>
                </ListItem>
                <ListItem tappable>
                    <label className="center" htmlFor={"status-toggle"}>
                        <p>Gebiet bereit zum Sammeln</p>
                    </label>
                    <div className="right">
                        <Switch
                            inputId={"status-toggle"}
                            checked={area.properties.status !== FeatureStatus.Planned}
                            onChange={it => setAssigned(it.value)}
                        />
                    </div>
                </ListItem>
                <ListHeader>{t(R.contact)}</ListHeader>
                <ListItem key={"contact-input"}>
                    <Input
                        value={area.properties.contact || ""}
                        onChange={(e) => setContact(area.id, e.target.value)}
                        placeholder={t(R.yourPhoneNumber)}
                    />
                </ListItem>
            </List>
            <AreaCollectorsList area={area}/>
        </Page>
    )
}

const Strikethrough = ({visible, children}: PropsWithChildren<{ visible: boolean }>) => {
    return visible ? (
        <s>{children}</s>
    ) : children
}

const AreaDetailList = ({areas, onSelect}: { areas: Area[], onSelect: (areaId: string) => void }) => {
    const renderAreaDetailRow = (area: Area, index?: number) => {
        return (
            <ListItem key={index} onClick={() => onSelect(area.id)} tappable>
                <div className='center'>
                    <Strikethrough visible={area.properties.status > FeatureStatus.Planned}>
                        <p>{area.properties.number}: {area.properties.name}</p>
                    </Strikethrough>
                </div>
                <div className='right'>
                    <Strikethrough visible={area.properties.status > FeatureStatus.Planned}>
                        <Nicknames feature={area}/>
                    </Strikethrough>
                </div>
            </ListItem>
        )
    }

    return (
        <Page>
            <List dataSource={areas} renderRow={renderAreaDetailRow}
                  renderHeader={() => <ListTitle>Gebiete</ListTitle>}/>
        </Page>
    )
}

export const AreaOverviewPage = () => {
    const {status, data: areas} = useAreas();
    const [selectedAreaId, setSelectedAreaId] = useState<string | undefined>(undefined)

    const sortedAreas = useMemo(() => {
        if (status !== ResultStatus.Success) return []
        return areas.sort((a, b) => {
            if (a.properties.status === b.properties.status) {
                return a.properties.number - b.properties.number
            }
            return a.properties.status - b.properties.status
        })
    }, [areas, status])

    const selectArea = useCallback((areaId: string) => {
        setSelectedAreaId(areaId)
    }, [])

    if (status !== ResultStatus.Success) {
        return <LoadingPage/>
    }

    return (
        <DesktopColumnLayout main={
            <AreaDetailList areas={sortedAreas} onSelect={selectArea}/>
        } detail={
            <AreaDetails area={areas.find(it => it.id === selectedAreaId)}/>
        }/>
    )
}
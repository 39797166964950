import {useT, useTFirebase} from "../../translation/Translate";
import React, {useCallback, useState} from "react";
import {createUserWithEmailAndPassword, sendEmailVerification, updateProfile} from "firebase/auth";
import {Button, Icon, Input, List, ListItem} from "react-onsenui";
import {R} from "../../translation/i18n";
import {FirebaseError} from "@firebase/app";
import {useAuth} from "../../../data/firebase/provider/AuthProvider";
import {LOGIN_PAGE_PATH} from "../../router/Router";

type RegisterFormProps = {
    success: (uid: string, nickname: string) => void,
    error: (e: string) => void
}
const RegisterForm = ({success, error}: RegisterFormProps) => {
    const t = useT()
    const tFirebase = useTFirebase();
    const auth = useAuth();
    const [nickname, setNickname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const register = useCallback(async () => {
        const name = nickname.trim()
        if (name === "") {
            error(t(R.nicknameInvalid))
        } else {
            try {
                const {user} = await createUserWithEmailAndPassword(auth, email.trim(), password)
                await sendEmailVerification(user, {url: `${window.location.origin}${LOGIN_PAGE_PATH}`})
                await updateProfile(user, {displayName: name})
                success(user.uid, name)
            } catch (err) {
                error(tFirebase(err as FirebaseError))
            }
        }
    }, [nickname, error, t, auth, email, password, success, tFirebase])

    return (
        <List>
            <ListItem key="register-form-nickname">
                <div className="left">
                    <Icon icon={{default: 'md-face'}}/>
                </div>
                <div className="center">
                    <Input
                        value={nickname}
                        onChange={(event) => {
                            setNickname(event.target.value)
                        }}
                        placeholder={t(R.nickname)}
                        type="text"
                    />
                </div>
            </ListItem>
            <ListItem key="register-form-email">
                <div className="left">
                    <Icon icon={{default: 'md-email'}}/>
                </div>
                <div className="center">
                    <Input
                        value={email}
                        onChange={(event) => {
                            setEmail(event.target.value)
                        }}
                        placeholder={t(R.email)}
                        type="email"
                    />
                </div>
            </ListItem>
            <ListItem key="register-form-password">
                <div className="left">
                    <Icon icon={{default: 'md-key'}}/>
                </div>
                <div className="center">
                    <Input
                        value={password}
                        onChange={(event) => {
                            setPassword(event.target.value)
                        }}
                        placeholder={t(R.password)}
                        type="password"
                    />
                </div>
            </ListItem>
            <ListItem key="register-form-submit">
                <Button modifier="large" onClick={register}>{t(R.register)}</Button>
            </ListItem>
        </List>
    )
};

export default RegisterForm
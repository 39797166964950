import {doc, setDoc} from "firebase/firestore";
import {useCallback, useEffect, useState} from "react";
import {useAuth, useUserId} from "./provider/AuthProvider";
import {UserProfile, userProfileConverter} from "./converter/UserProfileConverter";
import {
    CollectionDefinition,
    FirebaseResult,
    ResultStatus,
    useCollectionRef,
    useCollectionState,
    useDocState
} from "./Database";
import {useIdToken} from "react-firebase-hooks/auth";

export type UserRoles = {
    admin: boolean,
    head: boolean,
}

const definition: CollectionDefinition<UserProfile> = {
    id: 'user',
    converter: userProfileConverter
}

export const useUserRole = (): FirebaseResult<UserRoles, Error> => {
    const auth = useAuth();
    const [user, loading, error] = useIdToken(auth);
    const [claims, setClaims] = useState<UserRoles | null>(null)

    useEffect(() => {
        user?.getIdTokenResult(false)
            .then((token) => {
                setClaims({
                    admin: token.claims.admin === true,
                    head: token.claims.head === true || token.claims.admin === true,
                })
            })
    }, [user, setClaims])

    if (error) return {status: ResultStatus.Error, data: error}
    if (!loading && !user) return {status: ResultStatus.Error, data: Error("User is not loggedin")}
    if (loading || claims === null) return {status: ResultStatus.Loading, data: null}
    return {status: ResultStatus.Success, data: claims}
}

export const useNickname = () => {
    const auth = useAuth()
    const userId = useUserId();
    const {status, data: userProfile} = useDocState(definition, userId);
    const collectionRef = useCollectionRef(definition);
    const [nickname, updateNickname] = useState<string>("")

    const setNickname = useCallback(async (name: string) => {
        updateNickname(name)
        name = name.trim()
        if (name === "") {
            name = auth.currentUser?.displayName || ""
        }
        const ref = doc(collectionRef, userId);
        await setDoc(ref, {nickname: name}, {merge: true})
    }, [auth, collectionRef, userId])

    useEffect(
        () => {
            if (status === ResultStatus.Success && userProfile) {
                updateNickname(userProfile.nickname)
            }
            if (status === ResultStatus.Success && !userProfile) {
                setNickname(nickname)
            }
        },
        [nickname, setNickname, status, userProfile]
    );

    return {nickname, setNickname}
}

export const useNicknameAnonymous = () => {
    const collectionRef = useCollectionRef(definition);

    const setNickname = useCallback(async (userId: string, nickname: string) => {
        const ref = doc(collectionRef, userId);
        await setDoc(ref, {nickname}, {merge: true})
    }, [collectionRef]);

    return {setNickname}
}

export const useUserProfiles = () => useCollectionState(definition)
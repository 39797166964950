import {Popup} from "react-leaflet";
import {Area} from "../../../data/firebase/AreaCollection";
import StatusText from "../map/StatusText";
import {AreaStatusActionButton} from "./AreaStatusActionButton";
import {Nicknames} from "../map/Nicknames";

const PopupBody = ({feature}: { feature: Area }) => {
    return <>
        <strong>{feature.properties.number}: {feature.properties.name}</strong>
        <StatusText feature={feature}/>
        <Nicknames feature={feature}/>
        <AreaStatusActionButton feature={feature} modifier="quiet" details={true}/>
    </>
}
const AreaPopup = ({feature}: { feature: Area }) => (
    <Popup minWidth={230}>
        <PopupBody feature={feature}/>
    </Popup>
);

export default AreaPopup
import {useTranslation} from "react-i18next";
import {R} from "./i18n";
import {getTranslatedError, Language} from "@nafuzi/firebase-auth-error-translator";
import {FirebaseError} from "@firebase/app";
import {useCallback} from "react";

export const useT = () => {
    const {t} = useTranslation()

    return useCallback((key: R, values?: string) => {
        if (values) {
            return t(R[key], values);
        } else {
            return t(R[key]);
        }
    }, [t])
}

export const useTFirebase = () => {
    const {i18n} = useTranslation()

    return (error: FirebaseError) => getTranslatedError(i18n.language as Language, error.code)
}
import {BackButton, Input, List, ListHeader, ListItem, Page, Toolbar} from "react-onsenui";
import "leaflet/dist/leaflet.css"
import LoadingPage from "./LoadingPage";
import {useNavigate} from "react-router-dom";
import StatusText from "../feature/map/StatusText";
import {useT} from "../translation/Translate";
import {R} from "../translation/i18n";
import {useArea, useAreaStatusHandler} from "../../data/firebase/AreaCollection";
import {AreaStatusActionButton} from "../feature/area/AreaStatusActionButton";
import {ResultStatus} from "../../data/firebase/Database";
import {AreaMap} from "../feature/area/AreaMap";
import {AreaCollectorsList} from "../feature/area/AreaCollectorsList";
import {useUserId} from "../../data/firebase/provider/AuthProvider";
import ErrorPage from "./ErrorPage";
import {useUserRole} from "../../data/firebase/UserCollection";

export const AreaDetailPage = ({id}: { id: string }) => {
    const {status: areaStatus, data: area} = useArea(id)
    const userId = useUserId()
    const {status: roleStatus, data: roles} = useUserRole();
    const {setContact} = useAreaStatusHandler();
    const t = useT()
    const navigate = useNavigate()

    if (areaStatus === ResultStatus.Error) {
        return <ErrorPage errorMsg={area.message}/>
    }

    if (roleStatus === ResultStatus.Error) {
        return <ErrorPage errorMsg={roles.message}/>
    }

    if (areaStatus === ResultStatus.Loading || roleStatus === ResultStatus.Loading) {
        return <LoadingPage/>
    }

    const toolbar = () => <Toolbar>
        <div className="left">
            <BackButton onClick={() => navigate(-1)}>{t(R.back)}</BackButton>
        </div>
        <div className="center">{area.properties.number}: {area.properties.name}</div>
    </Toolbar>

    const contact = area.properties.collectors.includes(userId) || roles.head ? <List>
        <ListHeader>{t(R.contact)}</ListHeader>
        <ListItem key={"contact-input"}>
            <Input
                value={area.properties.contact || ""}
                onChange={(e) => setContact(area.id, e.target.value)}
                placeholder={t(R.yourPhoneNumber)}
            />
        </ListItem>
    </List> : null

    return (
        <Page renderToolbar={toolbar}>
            <List renderHeader={() => (
                <ListHeader>{t(R.location)}</ListHeader>
            )}>
                <ListItem key="item-detail-map">
                    <AreaMap area={area}/>
                </ListItem>
            </List>
            {contact}
            <AreaCollectorsList area={area}/>
            <List>
                <ListHeader>{t(R.status)}</ListHeader>
                <ListItem key="item-detail-status">
                    <StatusText feature={area}/>
                </ListItem>
                <ListItem key="item-detail-action">
                    <AreaStatusActionButton feature={area} modifier="large" details={false}/>
                </ListItem>
            </List>
        </Page>
    )
};

export default AreaDetailPage
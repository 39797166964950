import React from "react";
import {Navigate} from "react-router-dom";
import LoadingPage from "../page/LoadingPage";
import {useUser} from "../../data/firebase/provider/AuthProvider";

import {ResultStatus} from "../../data/firebase/Database";

const ProtectedRoute = ({children}: Props) => {
    const {status, data: user} = useUser();

    if (status === ResultStatus.Loading) {
        return <LoadingPage/>;
    }

    if (status === ResultStatus.Error) {
        return <LoadingPage/>;
    }

    if (!user) {
        return <Navigate to="login"/>;
    }

    return children;
}

type Props = {
    children: JSX.Element
};

export default ProtectedRoute
import {List, ListHeader, ListItem} from "react-onsenui";
import {generateFromString} from "generate-avatar";
import {useNavigate} from "react-router-dom";
import {useT} from "../../translation/Translate";
import {R} from "../../translation/i18n";
import {Area} from "../../../data/firebase/AreaCollection";
import {useNicknameCount} from "../map/Nicknames";
import {useCallback} from "react";

type Props = { areas: Area[] };
const AreaList = ({areas}: Props) => {
    const t = useT()
    const nicknameCount = useNicknameCount();
    const navigate = useNavigate()

    const generateAvatarSrc = useCallback(
        (feature: Area) =>
            `data:image/svg+xml;utf8,${generateFromString(String(feature?.id))}`,
        []
    );

    const Area = (feature: Area, idx: number) =>
        <ListItem key={`collection-point-${idx}`} modifier="chevron" onClick={() => navigate(`/area/${feature.id}`)}>
            <div className="left">
                <img
                    alt=""
                    className="list-item__thumbnail"
                    src={generateAvatarSrc(feature)}
                />
            </div>
            <div className="center">
                <p>{feature.properties.number}: {feature.properties.name}</p>
            </div>
            <div className="right">{nicknameCount(feature)}</div>
        </ListItem>

    const listHeader = <ListHeader>{t(R.myAreas)}</ListHeader>;

    return (
        areas.length > 0
            ? <List
                dataSource={areas.sort((a, b) => a.properties.status - b.properties.status)}
                renderHeader={() => listHeader}
                renderRow={Area}>
            </List>
            : <List renderHeader={() => listHeader}>
                <ListItem key="areas-empty">
                    <p>{t(R.goToMapAndSelectArea)}</p>
                </ListItem>
            </List>
    )
}

export default AreaList
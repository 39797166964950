import {BackButton, Button, Icon, List, ListHeader, ListItem, Page, Toolbar} from "react-onsenui";
import 'leaflet/dist/leaflet.css'
import {useCollectionPoint} from "../../data/firebase/CollectionPointCollection";
import LoadingPage from "./LoadingPage";
import {useNavigate} from "react-router-dom";
import OSMLayer from "../feature/map/OSMLayer";
import {MapContainer, Marker} from "react-leaflet";
import {useStatusIcon} from "../feature/map/Icon";
import {Nicknames} from "../feature/map/Nicknames";
import CollectionPointStatusActionButton from "../feature/collectionPoint/CollectionPointStatusActionButton";
import StatusText from "../feature/map/StatusText";
import {useT} from "../translation/Translate";
import {R} from "../translation/i18n";
import {LocationCircle} from "../feature/map/LocationCircle";
import {ResultStatus} from "../../data/firebase/Database";
import {toLatLng} from "../../utils/Geo";

export const CollectionPointDetailPage = ({id}: { id: string }) => {
    const {status: collectionPointStatus, data: collectionPoint} = useCollectionPoint(id)
    const t = useT()
    const navigate = useNavigate()
    const icon = useStatusIcon();

    if (collectionPointStatus !== ResultStatus.Success) {
        return <LoadingPage/>
    }

    const latLng = toLatLng(collectionPoint.geometry.coordinates)

    const openNavigationApp = () => {
        /*if (isIOS(false))
            window.open(`maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${coords[1]},${coords[0]}`);
        else
            window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${coords[1]},${coords[0]}`);*/
        window.open(`google.navigation:q=${latLng.lat},${latLng.lng}`)
    };

    const toolbar = () => <Toolbar>
        <div className="left">
            <BackButton onClick={() => navigate(-1)}>{t(R.back)}</BackButton>
        </div>
        <div className="center">{t(R.details)}</div>
    </Toolbar>

    return (
        <Page renderToolbar={toolbar}>
            <List>
                <ListHeader>{t(R.location)}</ListHeader>
                <ListItem key="item-detail-map">
                    <MapContainer center={latLng} zoom={16} scrollWheelZoom={true}
                                  style={{width: '100%', aspectRatio: '4/3'}}>
                        <OSMLayer/>
                        <LocationCircle/>
                        <Marker position={latLng} icon={icon(collectionPoint)}/>
                    </MapContainer>
                </ListItem>
                <ListItem key="item-detail-open-map">
                    <Button onClick={openNavigationApp}>{t(R.openNavigationApp)} <Icon
                        icon={{default: 'md-open-in-new'}}/></Button>
                </ListItem>
                <ListHeader>{t(R.preparer)}</ListHeader>
                <ListItem key="item-detail-nicknames">
                    <Nicknames feature={collectionPoint}/>
                </ListItem>
                <ListHeader>{t(R.status)}</ListHeader>
                <ListItem key="item-detail-status">
                    <StatusText feature={collectionPoint}/>
                </ListItem>
                <ListItem key="item-detail-action">
                    <CollectionPointStatusActionButton collectionPoint={collectionPoint} modifier="large"
                                                       details={false}/>
                </ListItem>
            </List>
        </Page>
    )
};

export default CollectionPointDetailPage
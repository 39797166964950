import {Auth, connectAuthEmulator, getAuth} from 'firebase/auth';
import React, {createContext, useContext, useMemo} from "react";
import {DebugOptions} from "../../../debug/DebugOptions";
import {useFirebaseApp} from "./FirebaseAppProvider";
import {useAuthState} from "react-firebase-hooks/auth";

import {fromLoadingHook} from "../Database";

const AuthContext = createContext<Auth | null>(null);

export function AuthProvider({children}: Props) {
    const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`

    // initialize Database and Auth with the normal Firebase SDK functions
    const auth = getAuth(app);

    console.log("Runs in development mode", DebugOptions.isDevelopment)
    // Check for dev/test mode however your app tracks that.
    // `process.env.NODE_ENV` is a common React pattern
    if (DebugOptions.isDevelopment) {
        // Set up emulators
        connectAuthEmulator(auth, 'http://localhost:9001');
    }

    // any child components will be able to use `useUser`, `useDatabaseObjectData`, etc
    return (
        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within a FirebaseAuthProvider');
    }
    return context;
};

export const useUser = () => {
    const auth = useAuth();
    const res = useAuthState(auth);
    return fromLoadingHook(res)
}

export const useUserId = () => {
    const auth = useAuth()
    if (!auth || !auth.currentUser) {
        throw Error("User not loggedin")
    }
    return useMemo(
        () => auth.currentUser!.uid,
        [auth.currentUser]
    )
}

type Props = {
    children: JSX.Element
};
import {FirestoreDataConverter} from "@firebase/firestore";
import {DocumentData} from "firebase/firestore";
import {CollectionPoint, CollectionPointSize} from "../CollectionPointCollection";
import {FeatureStatus} from "../../../view/feature/map/StatusText";

export const collectionPointConverter: FirestoreDataConverter<CollectionPoint> = {
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options);
        return {
            // Set Defaults
            properties: {
                status: FeatureStatus.Unknown,
                size: CollectionPointSize.Unknown,
                additional: false,
            },
            // Set Data
            ...data,
            // Set Id
            id: snapshot.id,
        } as CollectionPoint
    }, toFirestore(modelObject): DocumentData {
        delete modelObject.id;
        return modelObject;
    }
}
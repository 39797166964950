import {useNavigate, useRouteError} from "react-router-dom";
import {BackButton, Button, Card, Page, Toolbar} from "react-onsenui";
import {R} from "../translation/i18n";
import {useT} from "../translation/Translate";
import {FIRST_PAGE_PATH} from "../router/Router";

const ErrorPage = ({errorMsg}: { errorMsg?: string }) => {
    const t = useT()
    const error = useRouteError();
    const navigate = useNavigate();
    console.error(error);

    const backToFirstPage = () => navigate(FIRST_PAGE_PATH);

    const toolbar = () => {
        return (
            <Toolbar>
                <div className="left">
                    <BackButton onClick={backToFirstPage}>{t(R.back)}</BackButton>
                </div>
                <div className="center">{t(R.error)}</div>
            </Toolbar>
        );
    }

    return (
        <Page renderToolbar={toolbar}>
            <Card>
                <img src="https://cataas.com/cat/gif" alt="random cat"/>
                <div className="title">
                    <h1>Oops!</h1>
                    <p>{t(R.errorMsg)}</p>
                </div>
                <div className="content">
                    <p style={{color: "red"}}>
                        <i>{
                            // @ts-ignore
                            errorMsg || error.statusText || error.message
                        }</i>
                    </p>
                </div>
            </Card>
            <Button modifier="large" onClick={backToFirstPage}>
                {t(R.back)}
            </Button>
        </Page>
    );
}

export default ErrorPage
import {useUserProfiles} from "../../../data/firebase/UserCollection";
import {Area} from "../../../data/firebase/AreaCollection";
import {CollectionPoint} from "../../../data/firebase/CollectionPointCollection";
import {useCallback, useEffect, useMemo, useState} from "react";
import {ResultStatus} from "../../../data/firebase/Database";
import {UserProfile} from "../../../data/firebase/converter/UserProfileConverter";

const notEmpty = <TValue extends any>(value: TValue | null | undefined): value is TValue => !(
    value === null || value === undefined || value === ""
);

const sanitizedNames = (feature: Area | CollectionPoint) => {
    const {preparer, collectors, additionalCollectors} = {
        preparer: [], collectors: [], additionalCollectors: [],
        ...(
            feature?.properties || {}
        )
    }
    const userIds = preparer
        .concat(collectors)
        .filter(notEmpty)
    const additionalNames = additionalCollectors
        .filter(notEmpty)
    return {userIds, additionalNames}
}

export const useNicknameCount = () =>
    useCallback(
        (feature: Area | CollectionPoint) =>
            Object.values(sanitizedNames(feature))
                .flatMap(e => e).length,
        []
    )

export const useFeatureNameMapper = (feature: Area | CollectionPoint) => {
    const {status, data: userProfiles} = useUserProfiles()
    const [mappedNames, setMappedNames] = useState<
        { registeredNames: UserProfile[], additionalNames: string[] }
    >({registeredNames: [], additionalNames: []})

    useEffect(() => {
        if (status !== ResultStatus.Success) return
        const {userIds, additionalNames} = sanitizedNames(feature);
        const registeredNames = userIds
            .map(userId => userProfiles.find(profile => profile.id === userId))
            .filter(notEmpty)

        setMappedNames({registeredNames, additionalNames})
    }, [feature, status, userProfiles])

    return mappedNames
}

export const Nicknames = ({feature}: { feature: Area | CollectionPoint }) => {
    const mappedNames = useFeatureNameMapper(feature)

    const nicknames = useMemo(() => {
        const names = mappedNames.registeredNames
            .map(it => it.nickname)
            .concat(mappedNames.additionalNames)
            .sort()
        if (names.length === 1) {
            return `${names[0]}`
        } else if (names.length > 0) {
            return `${names.join(", ")} (${names.length})`
        } else {
            return null
        }
    }, [mappedNames]);

    return nicknames ? <p>{nicknames}</p> : null
}

import {LayerGroup, Polygon} from "react-leaflet";
import {useAreas} from "../../../data/firebase/AreaCollection";
import AreaPopup from "./AreaPopup";
import {useUserId} from "../../../data/firebase/provider/AuthProvider";

import {ResultStatus} from "../../../data/firebase/Database";
import {toLatLng} from "../../../utils/Geo";

const AreaLayerGroup = ({disabled = false}: { disabled: boolean }) => {
    const {status: areaStatus, data: areas} = useAreas()
    const userId = useUserId();

    if (areaStatus !== ResultStatus.Success) {
        return null
    }

    return (
        <LayerGroup>
            {
                areas.map(area => {
                    let pathOptions: { color: string, fillColor?: string } = {color: 'blue'}
                    if (area.properties.collectors
                        && area.properties.collectors.includes(userId)) {
                        pathOptions = {
                            color: 'red'
                        }
                    } else if (area.properties.collectors
                        && area.properties.collectors.length > 0) {
                        pathOptions = {
                            color: 'grey'
                        }
                    }

                    const coordinates = area.geometry.coordinates.map(it => toLatLng(it));
                    return <Polygon
                        key={area.id}
                        positions={coordinates}
                        pathOptions={pathOptions}
                    >
                        {disabled ? null : <AreaPopup feature={area}/>}
                    </Polygon>
                })
            }
        </LayerGroup>
    )
}

export default AreaLayerGroup
import {collection, doc, query} from "firebase/firestore";
import {FirestoreDataConverter, QueryConstraint} from "@firebase/firestore";
import {useCollectionData, useDocumentData} from "react-firebase-hooks/firestore";
import {useFirestore} from "./provider/FirestoreProvider";

export enum ResultStatus {
    Loading,
    Error,
    Success
}

export type FirebaseResult<K, T> = {
    status: ResultStatus.Loading,
    data: null
} | {
    status: ResultStatus.Error,
    data: NonNullable<T>
} | {
    status: ResultStatus.Success,
    data: K
}

export const fromLoadingHook = <K, T>(res: [K | undefined, boolean, T | undefined, any?]): FirebaseResult<K, T> => {
    const [value, loading, error] = res
    if (loading) return {status: ResultStatus.Loading, data: null}
    if (error) return {status: ResultStatus.Error, data: error}
    return {status: ResultStatus.Success, data: value!!}
}

export const fromLoadingHookAndMap = <K, T, U>(res: [K | undefined, boolean, T | undefined, any?], mapper: (value: K) => U): FirebaseResult<U | null, T> => {
    const [value, loading, error] = res
    if (loading) return {status: ResultStatus.Loading, data: null}
    if (error) return {status: ResultStatus.Error, data: error}
    return {status: ResultStatus.Success, data: value ? mapper(value) : null}
}

export interface CollectionDefinition<T> {
    converter: FirestoreDataConverter<T>,
    id: string
}

export const useCollectionRef = <U>(def: CollectionDefinition<U>) => {
    const firestore = useFirestore()
    return collection(firestore, def.id).withConverter(def.converter)
}

export const useCollectionState = <U>(def: CollectionDefinition<U>, ...queryConstraints: QueryConstraint[]) => {
    const collectionRef = useCollectionRef(def);
    const res = useCollectionData(query(collectionRef, ...queryConstraints));
    return fromLoadingHook(res)
};

export const useDocState = <U>(def: CollectionDefinition<U>, docId: string) => {
    const collectionRef = useCollectionRef(def);
    const docRef = doc(collectionRef, docId);
    const res = useDocumentData(docRef);
    return fromLoadingHook(res)
};

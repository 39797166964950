import React from 'react';
import './App.css';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import {FirebaseProviders} from "./data/firebase/provider/FirebaseProviders";
import Router from "./view/router/Router";
import './view/translation/i18n'

function App() {

    return (
        <FirebaseProviders>
            <Router/>
        </FirebaseProviders>
    );
}

export default App;

export const makeUnique = <T>(list: T[]) => {
    const seen = {};
    const out = [];
    const len = list.length;
    let j = 0;
    for (var i = 0; i < len; i++) {
        var item = list[i];
        if (seen[item] !== 1) {
            seen[item] = 1;
            out[j++] = item;
        }
    }
    return out;
};
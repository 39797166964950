import {addDoc, arrayUnion, doc, GeoPoint, query, updateDoc, where} from "firebase/firestore";
import {Feature, Point} from "geojson";
import {useCallback} from "react";
import {CollectionDefinition, fromLoadingHook, useCollectionRef, useCollectionState, useDocState} from "./Database";
import {FeatureStatus} from "../../view/feature/map/StatusText";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {useUserId} from "./provider/AuthProvider";
import {collectionPointConverter} from "./converter/CollectionPointConverter";
import {MapPositionToGeoPoint} from "../../utils/Geo";

export enum CollectionPointSize {
    Unknown = 0,
    Small = 1,
    Medium = 2,
    Large = 3
}

export type CollectionPointProperties = {
    additional: boolean,
    size: CollectionPointSize,
    status: FeatureStatus,
    preparer?: string[],
    truck?: string
}

export interface CollectionPoint extends MapPositionToGeoPoint<Feature<Point, CollectionPointProperties>> {
    id: string
}

const PROPERTIES = {
    STATUS: "properties.status",
    PREPARER: 'properties.preparer',
    ADDITIONAL: "properties.additional",
    TRUCK: "properties.truck"
};

const definition: CollectionDefinition<CollectionPoint> = {
    id: 'collection-point',
    converter: collectionPointConverter
}

export const useAllCollectionPoints = () => useCollectionState(definition);

export const useCollectionPoints = () => useCollectionState(
    definition,
    where(PROPERTIES.ADDITIONAL, "!=", true)
);

export const useAdditionalCollectionPoints = () => useCollectionState(
    definition,
    where(PROPERTIES.ADDITIONAL, "==", true)
);

export const useCollectionPoint = (id: string) => useDocState(definition, id);

export const useCollectionPointCollectionRef = () => useCollectionRef(definition);

export function useCollectionPointsOfCurrentUser() {
    const userId = useUserId();
    const pointsCollection = useCollectionPointCollectionRef();
    const whereCurrentUser = where(PROPERTIES.PREPARER, "array-contains", userId)

    const pointsQuery = query(pointsCollection, whereCurrentUser)
    const res = useCollectionData(pointsQuery);
    return fromLoadingHook(res)
}

export const useCollectionPointActions = () => {
    const collectionRef = useCollectionPointCollectionRef()
    const userId = useUserId();

    const add = useCallback(async (geoPoint: GeoPoint, size: CollectionPointSize) => {
        const data: Omit<CollectionPoint, "id"> = {
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: geoPoint
            },
            properties: {
                size,
                preparer: [userId],
                status: FeatureStatus.Prepared,
                additional: true
            }
        };
        await addDoc(collectionRef, data);
    }, [collectionRef, userId])

    const assign = useCallback(async (id: string) => {
        await updateDoc(
            doc(collectionRef, id),
            PROPERTIES.PREPARER,
            arrayUnion(userId),
            PROPERTIES.STATUS,
            FeatureStatus.AssignedForPrepare
        )
    }, [collectionRef, userId])

    const setStatus = useCallback(async (id: string, status: FeatureStatus) => {
        await updateDoc(
            doc(collectionRef, id),
            PROPERTIES.STATUS,
            status
        )
    }, [collectionRef])

    const setTruckAndStatus = useCallback(async (collectionPointId: string, truckId: string | null, status: FeatureStatus) => {
        await updateDoc(
            doc(collectionRef, collectionPointId),
            PROPERTIES.STATUS,
            status,
            PROPERTIES.TRUCK,
            truckId
        )
    }, [collectionRef])

    return {
        add, assign, setStatus, setTruckAndStatus
    }
};
import {MapContainer, Polygon, PolygonProps, useMap} from "react-leaflet";
import {useEffect, useState} from "react";
import {calcBoundaries, toLatLng} from "../../../utils/Geo";
import {LatLng} from "leaflet";
import {Area} from "../../../data/firebase/AreaCollection";
import OSMLayer from "../map/OSMLayer";
import {LocationCircle} from "../map/LocationCircle";

const FitPolygon = (props: PolygonProps) => {
    const {positions} = props
    const map = useMap();

    useEffect(() => {
        if (positions.length > 0) {
            const latLngBounds = calcBoundaries(positions as LatLng[]);
            map.fitBounds(latLngBounds)
        }
    }, [map, positions]);

    return (
        <Polygon {...props} />
    )
}
export const AreaMap = ({area}: { area: Area }) => {
    const [positions, setPositions] = useState<LatLng[]>([])

    useEffect(() => {
        if (area) {
            const latLngs = area.geometry.coordinates.map(it => toLatLng(it))
            setPositions(latLngs)
        }
    }, [area]);

    return (
        <MapContainer
            center={[49.722, 11.078]}
            zoom={13}
            scrollWheelZoom={true}
            style={{width: "100%", minHeight: "400px"}}
        >
            <OSMLayer/>
            <LocationCircle/>
            <FitPolygon
                key={area.id}
                positions={positions}
                pathOptions={{
                    color: "red",
                    fillColor: "transparent"
                }}
            />
        </MapContainer>
    )
}
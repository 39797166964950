import {sendEmailVerification} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import React, {useCallback} from "react";
import LoadingPage from "./LoadingPage";
import {Button, Card, Page, Toolbar} from "react-onsenui";
import {useT} from "../translation/Translate";
import {R} from "../translation/i18n";
import LogoutButton from "../feature/auth/LogoutButton";
import {FIRST_PAGE_PATH, LOGIN_PAGE_PATH} from "../router/Router";
import {useUser} from "../../data/firebase/provider/AuthProvider";

import {ResultStatus} from "../../data/firebase/Database";

export function VerifyEmailPage() {
    const {status: signInCheckStatus, data: user} = useUser();
    const navigate = useNavigate()
    const t = useT()

    if (signInCheckStatus === ResultStatus.Success) {
        if (!user) {
            navigate(LOGIN_PAGE_PATH)
        } else if (user
            && user.emailVerified) {
            navigate(FIRST_PAGE_PATH)
        }
    }

    const sendEmailVerificationAgain = useCallback(async () => {
        if (signInCheckStatus === ResultStatus.Success
            && user
            && !user.emailVerified) {
            sendEmailVerification(user, {url: `${window.location.origin}${LOGIN_PAGE_PATH}`}).then(() => {
                console.log("Verification email sent")
            })
        }
    }, [signInCheckStatus, user])

    if (signInCheckStatus !== ResultStatus.Success) {
        return <LoadingPage/>
    }

    const toolbar = () =>
        <Toolbar>
            <div className="center">{t(R.verifyEmail)}</div>
        </Toolbar>;

    return <Page renderToolbar={toolbar}>
        <Card>
            <p>{t(R.verificationEmailSent, {email: user.email})}</p>
            <Button
                onClick={sendEmailVerificationAgain}
                modifier="large--quiet"
            >{t(R.sendVerificationEmailAgain)}</Button>
            <LogoutButton/>
        </Card>
    </Page>
}

export default VerifyEmailPage
import * as React from "react";
import {lazy, useMemo} from "react";
import {createBrowserRouter, Outlet, RouterProvider,} from "react-router-dom";
import LoginPage from "../page/LoginPage";
import ErrorPage from "../page/ErrorPage";
import ProtectedRoute from "./ProtectedRoute";
import {BottomTapLayout} from "../layout/BottomTapLayout";
import SettingsPage from "../page/SettingsPage";
import MapPage from "../page/MapPage";
import ItemPage from "../page/ItemPage";
import CollectionPointDetailPage from "../page/CollectionPointDetailPage";
import {useLocation, useParams} from "react-router";
import VerifyEmailPage from "../page/VerifyEmailPage";
import AreaDetailPage from "../page/AreaDetailPage";
import AddPage from "../page/AddPage";
import {AreaOverviewPage} from "../page/AreaOverviewPage";
import {useUserRole} from "../../data/firebase/UserCollection";
import {ResultStatus} from "../../data/firebase/Database";
import LoadingPage from "../page/LoadingPage";
import {toFloatOrElse} from "../../utils/Number";
import {TruckAssignPage} from "../page/TruckAssignPage";

const ResetDataPage = lazy(() => import("../page/DebugPage"));

export const LOGIN_PAGE_PATH = "/login"
export const VERIFY_EMAIL_PAGE_PATH = "/verify"
export const FIRST_PAGE_PATH = "/"
export const ADD_COLLECTION_POINT_PAGE = "/add-collection-point"

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const {search} = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

const AreaDetailRoute = () => {
    let params = useParams();
    return <AreaDetailPage id={params.itemId}/>
}

const CollectionPointDetailRoute = () => {
    let params = useParams();
    return <CollectionPointDetailPage id={params.itemId}/>
}

const MapRoute = () => {
    const query = useQuery();
    return <MapPage
        lat={toFloatOrElse(query.get("lat"), 49.722)}
        lng={toFloatOrElse(query.get("lng"), 11.078)}
        zoom={toFloatOrElse(query.get("zoom"), 13)}
    />
}

const HeadRoute = () => {
    const {status, data} = useUserRole();
    if (status === ResultStatus.Loading) return <LoadingPage/>
    if (status === ResultStatus.Error) return <ErrorPage errorMsg={data.message}/>
    if (!data.head) return <ErrorPage errorMsg={"Missing Permission"}/>
    return <Outlet/>
}

const AdminRoute = () => {
    const {status, data} = useUserRole();
    if (status === ResultStatus.Loading) return <LoadingPage/>
    if (status === ResultStatus.Error) return <ErrorPage errorMsg={data.message}/>
    if (!data.admin) return <ErrorPage errorMsg={"Missing Permission"}/>
    return <Outlet/>
}

const routesConfig = createBrowserRouter([
    {
        path: "/login",
        element: <LoginPage/>,
        errorElement: <ErrorPage/>,
    },
    {
        path: "/verify",
        element: <VerifyEmailPage/>,
        errorElement: <ErrorPage/>,
    },
    {
        path: "/area/:itemId",
        element: (
            <ProtectedRoute>
                <AreaDetailRoute/>
            </ProtectedRoute>
        ),
        errorElement: <ErrorPage/>,
    },
    {
        path: "/collection-point/:itemId",
        element: (
            <ProtectedRoute>
                <CollectionPointDetailRoute/>
            </ProtectedRoute>
        ),
        errorElement: <ErrorPage/>,
    },
    {
        path: ADD_COLLECTION_POINT_PAGE,
        element: (
            <ProtectedRoute>
                <AddPage/>
            </ProtectedRoute>
        ),
        errorElement: <ErrorPage/>,
    },
    {
        path: "/head",
        element: (
            <HeadRoute/>
        ),
        errorElement: <ErrorPage/>,
        children:
            [
                {
                    path: "area",
                    element: <AreaOverviewPage/>
                },
                {
                    path: "truck",
                    element: <TruckAssignPage/>
                }
            ]
    },
    {
        path: "/admin",
        element: (
            <AdminRoute/>
        ),
        errorElement: <ErrorPage/>,
        children:
            [
                {
                    path: "data",
                    element: <ResetDataPage/>
                }
            ]
    },
    {
        path: "/",
        element: (
            <ProtectedRoute>
                <BottomTapLayout/>
            </ProtectedRoute>
        ),
        errorElement: <ErrorPage/>,
        children:
            [
                {
                    index: true,
                    element: <MapRoute/>
                },
                {
                    path: "item",
                    element: <ItemPage/>
                },
                {
                    path: "settings",
                    element: <SettingsPage/>
                }
            ]
    },
]);

const Router = () => <RouterProvider router={routesConfig}/>

export default Router
import {Page, ProgressCircular, Toolbar} from 'react-onsenui'
import {useT} from "../translation/Translate";
import {R} from "../translation/i18n";

function LoadingPage(): JSX.Element {
    const t = useT()

    const toolbar = () => <Toolbar>
        <div className="center">{t(R.loading)}</div>
    </Toolbar>

    return (
        <Page renderToolbar={toolbar}>
            <ProgressCircular className="center" indeterminate={true} style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: 'auto',
                height: '64px',
                width: '64px'
            }}/>
        </Page>
    )
}

export default LoadingPage
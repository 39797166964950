import {
    connectFirestoreEmulator,
    Firestore,
    getFirestore,
    initializeFirestore,
    persistentLocalCache,
    persistentMultipleTabManager
} from 'firebase/firestore'; // Firebase v9+
import React, {createContext, useContext} from "react";
import {DebugOptions} from "../../../debug/DebugOptions";
import {useFirebaseApp} from './FirebaseAppProvider';

const FirestoreContext = createContext<Firestore | null>(null);

const FirestoreProduction = ({children}: Props) => {
    const app = useFirebaseApp();

    initializeFirestore(app, {
        localCache: persistentLocalCache({
            tabManager: persistentMultipleTabManager()
        }), // Enable Firebase offline persistence
    });

    const firestore = getFirestore(app)

    return (
        <FirestoreContext.Provider value={firestore}>
            {children}
        </FirestoreContext.Provider>
    );
}

const FirestoreDevelopment = ({children}: Props) => {
    const app = useFirebaseApp();
    const firestore = getFirestore(app)
    // Set up emulators
    connectFirestoreEmulator(firestore, 'localhost', 9002);

    return (
        <FirestoreContext.Provider value={firestore}>
            {children}
        </FirestoreContext.Provider>
    );
}

export function FirestoreProvider({children}: Props) {
    if (DebugOptions.isDevelopment) {
        return <FirestoreDevelopment children={children}/>
    } else {
        return <FirestoreProduction children={children}/>
    }
}

export const useFirestore = () => {
    const context = useContext(FirestoreContext);
    if (!context) {
        throw new Error('useFirestore must be used within a FirestoreProvider');
    }
    return context;
};

type Props = {
    children: JSX.Element
};
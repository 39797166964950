import {CollectionPoint} from "../../../data/firebase/CollectionPointCollection";
import useStatusMapper from "./StatusMapper";
import {R} from "../../translation/i18n";
import {useT} from "../../translation/Translate";
import {Area} from "../../../data/firebase/AreaCollection";

export enum FeatureStatus {
    Planned,
    AssignedForPrepare,
    Prepared,
    Ready,
    AssignedForPick,
    Picked,
    Unknown,
}

const StatusText = ({feature}: { feature: CollectionPoint | Area }) => {
    const {statusToString} = useStatusMapper();
    const t = useT()
    return <p>{t(R.status)}: {statusToString(feature.properties.status)}</p>;
};

export default StatusText
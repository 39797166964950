import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import {DebugOptions} from "../../debug/DebugOptions";

export enum R {
    abort,
    add,
    addAdditionalCollector,
    addCollectionPoint,
    additionalCollectionPoints,
    areas,
    assign,
    assignedForPick,
    assignedForPrepare,
    back,
    check,
    collectionPoints,
    collectors,
    contact,
    delete,
    details,
    email,
    english,
    enterName,
    error,
    errorMsg,
    finalize,
    german,
    goToMapAndSelectArea,
    goToMapAndSelectCollectionPoint,
    large,
    loading,
    location,
    login,
    loginFailed,
    loginWithGoogle,
    logout,
    management,
    map,
    medium,
    myAreas,
    myCollectionPoints,
    myTruck,
    nickname,
    nicknameInvalid,
    noTrucksOpen,
    openNavigationApp,
    or,
    password,
    picked,
    planned,
    prepared,
    preparer,
    ready,
    register,
    release,
    resetPassword,
    resetPasswordEmailSent,
    sendVerificationEmailAgain,
    settings,
    small,
    status,
    take,
    unknown,
    verificationEmailSent,
    verifyEmail,
    yourPhoneNumber,
    yours,
}

type Translation = { [key in keyof typeof R]: string };

const de: Translation = {
    abort: "Abbrechen",
    add: "Hinzufügen",
    addAdditionalCollector: "Sammler hinzufügen",
    addCollectionPoint: "Sammelstelle eintragen",
    additionalCollectionPoints: "Zusätzliche Sammelstellen",
    areas: "Gebiete",
    assign: "Sammelstelle übernehmen",
    assignedForPick: "LKW zugewiesen",
    assignedForPrepare: "Zugewiesen",
    back: "Zurück",
    check: "Überprüfen",
    collectionPoints: "Sammelstellen",
    collectors: "Sammler",
    contact: "Kontakt",
    delete: "Löschen",
    details: "Details",
    email: "E-Mail",
    english: "Englisch",
    enterName: "Namen eingeben",
    error: "Fehler",
    errorMsg: "Entschuldigung ein Fehler ist aufgetreten. Hier ist eine Katze für dich.",
    finalize: "Endgültig festlegen",
    german: "Deutsch",
    goToMapAndSelectArea: "Noch kein Gebiet ausgewählt. Wähle auf der Karte eins aus.",
    goToMapAndSelectCollectionPoint: "Noch keine Sammelstelle ausgewählt. Wähle auf der Karte welche aus",
    large: "Groß",
    loading: "Lade",
    location: "Ort",
    login: "Anmelden",
    loginFailed: "Login fehlgeschlagen",
    loginWithGoogle: "Mit Google anmelden",
    logout: "Abmelden",
    management: "Verwaltung",
    map: "Karte",
    medium: "Mittel",
    myAreas: "Meine Gebiete",
    myCollectionPoints: "Meine Sammelstellen",
    myTruck: "Mein LKW",
    nickname: "Benutzername",
    nicknameInvalid: "Benutzername ist ungültig",
    noTrucksOpen: "Gerade ist kein LKW frei",
    openNavigationApp: "Karte öffnen",
    or: "Oder",
    password: "Passwort",
    picked: "Abgeholt",
    planned: "Geplant",
    prepared: "Aufgebaut",
    preparer: "Aufbauer",
    ready: "Zur Abholung bereit",
    register: "Registrieren",
    release: "Abgeben",
    resetPassword: "Passwort zurücksetzen",
    resetPasswordEmailSent: "Passwort zurücksetzen E-Mail wurde versendet",
    sendVerificationEmailAgain: "Bestätigungs E-Mail erneut senden",
    settings: "Einstellungen",
    small: "Klein",
    status: "Status",
    take: "Übernehmen",
    unknown: "Unbekannt",
    verificationEmailSent: "Bestätigungs E-Mail an {{email}} gesendet",
    verifyEmail: "E-Mail bestätigen",
    yourPhoneNumber: "Deine Telefonnummer",
    yours: "Deins",
}

const en: Translation = {
    abort: "Abort",
    add: "Add",
    addAdditionalCollector: "Add collector",
    addCollectionPoint: "Add collection point",
    additionalCollectionPoints: "Additional collection points",
    areas: "Areas",
    assign: "prepare collection point",
    assignedForPick: "Assigned for pickup",
    assignedForPrepare: "Assigned for prepare",
    back: "Back",
    check: "Check",
    collectionPoints: "collection points",
    collectors: "Collectors",
    contact: "Contact",
    delete: "Delete",
    details: "Details",
    email: "Email",
    english: "English",
    enterName: "Enter name",
    error: "Error",
    errorMsg: "Sorry, an unexpected error has occurred, but here is a cat for you.",
    finalize: "Finalize",
    german: "German",
    goToMapAndSelectArea: "Go to map and select areas",
    goToMapAndSelectCollectionPoint: "Go to map and select collection points",
    large: "Large",
    loading: "Loading",
    location: "Location",
    login: "Login",
    loginFailed: "login failed",
    loginWithGoogle: "Sign in with Google",
    logout: "Logout",
    management: "Management",
    map: "Map",
    medium: "Medium",
    myAreas: "My areas",
    myCollectionPoints: "My collection points",
    myTruck: "My truck",
    nickname: "Nickname",
    nicknameInvalid: "Nickname is invalid",
    noTrucksOpen: "No trucks open for join",
    openNavigationApp: "Open map",
    or: "or",
    password: "Password",
    picked: "picked up",
    planned: "Planned",
    prepared: "Prepared",
    preparer: "Preparer",
    ready: "Ready for pickup",
    register: "Register",
    release: "Release",
    resetPassword: "reset password",
    resetPasswordEmailSent: "Reset password email sent",
    sendVerificationEmailAgain: "Send verification email again",
    settings: "Settings",
    small: "Small",
    status: "Status",
    take: "Take",
    unknown: "Unknown",
    verificationEmailSent: "Verification email sent to {{email}}",
    verifyEmail: "Verify email",
    yourPhoneNumber: "Insert your phone number",
    yours: "Yours",
}

const resources = {
    de: {translation: de}, en: {translation: en}
};

i18next
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources, //lng: "de", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option
        fallbackLng: "en", interpolation: {
            escapeValue: false // react already safes from xss
        }, debug: DebugOptions.isDevelopment
    });

export default i18next;
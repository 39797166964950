import {Area, useAreaStatusHandler} from "../../../data/firebase/AreaCollection";
import {useT} from "../../translation/Translate";
import {useUserRole} from "../../../data/firebase/UserCollection";
import {useCallback} from "react";
import ons from "onsenui";
import {R} from "../../translation/i18n";
import {Button, List, ListHeader, ListItem} from "react-onsenui";
import {FeatureStatus} from "../map/StatusText";
import {ResultStatus} from "../../../data/firebase/Database";
import {useFeatureNameMapper} from "../map/Nicknames";

type Name = {
    id: string,
    name: string,
    additional: boolean
}

export const AreaCollectorsList = ({area}: { area: Area }) => {
    const t = useT()
    const mappedNames = useFeatureNameMapper(area)
    const {
        removeCollector,
        addAdditionalCollector,
        removeAdditionalCollector,
    } = useAreaStatusHandler()
    const {status: rolesStatus, data: roles} = useUserRole()

    const click = useCallback(async () => {
        const name = await ons.notification.prompt(t(R.enterName), {
            title: t(R.addAdditionalCollector)
        }) as string
        if (name && name !== "") {
            await addAdditionalCollector(area.id, name)
        }
    }, [t, addAdditionalCollector, area])

    if (rolesStatus !== ResultStatus.Success) {
        return null
    }

    const additionalNames = mappedNames
        .additionalNames
        ?.map((name, idx) => (
            {id: idx.toString(), name, additional: true} as Name
        )) || []

    const nicknames = mappedNames
        .registeredNames
        .map(profile => (
            {id: profile.id, name: profile.nickname, additional: false} as Name
        ))
        .concat(additionalNames)
    //.join(", ")

    const renderRemoveButton = (name: Name) => {
        if (area.properties.status === FeatureStatus.Planned) {
            if (name.additional) {
                return <Button modifier="quiet"
                               onClick={() => removeAdditionalCollector(area.id, name.name)}> ❌ </Button>
            }
            if (roles.head) {
                return <Button modifier="quiet" onClick={() => removeCollector(area.id, name.id)}> ❌ </Button>
            }
        }
        return null
    }

    return <List
        dataSource={nicknames}
        renderHeader={() => (
            <ListHeader>{t(R.collectors)}</ListHeader>
        )}
        renderRow={name => (
            <ListItem key={`area-detail-${name.id}`}>
                <div className="center"><p>{name.name} {name.additional ? "" : "💙"}</p></div>
                <div className="right">{renderRemoveButton(name)}</div>
            </ListItem>
        )}
        renderFooter={() => {
            return (
                area.properties.status === FeatureStatus.Planned
                    ? <ListItem key="area-detail-actions">
                        <Button onClick={click} modifier="large--quiet">➕ {t(R.addAdditionalCollector)}</Button>
                    </ListItem>
                    : null
            )
        }}
    />;
}
import {LayersControl, MapContainer, useMap} from "react-leaflet";
import 'leaflet/dist/leaflet.css'
import {Fab, Icon, Page} from "react-onsenui";
import {
    AdditionalCollectionPointLayerGroup,
    CollectionPointLayerGroup
} from "../feature/collectionPoint/CollectionPointLayerGroup";
import OSMLayer from "../feature/map/OSMLayer";
import {useT} from "../translation/Translate";
import {R} from "../translation/i18n";
import {LocationCircle} from "../feature/map/LocationCircle";
import AreaLayerGroup from "../feature/area/AreaLayerGroup";
import {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {ADD_COLLECTION_POINT_PAGE} from "../router/Router";

const MapContainerContent = () => {
    const t = useT()

    return <LayersControl position="topright">
        <LayersControl.Overlay checked name={t(R.collectionPoints)}>
            <CollectionPointLayerGroup/>
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name={t(R.additionalCollectionPoints)}>
            <AdditionalCollectionPointLayerGroup/>
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name={t(R.areas)}>
            <AreaLayerGroup disabled={false}/>
        </LayersControl.Overlay>
    </LayersControl>
}

const AddLocationFab = () => {
    const map = useMap()
    const navigate = useNavigate()
    const addLocation = useCallback(() => {
        const center = map.getCenter();
        navigate(ADD_COLLECTION_POINT_PAGE
            + "?lat=" + center.lat
            + "&lng=" + center.lng
            + "&zoom=" + map.getZoom())
    }, [map, navigate])

    return (
        <Fab position="bottom right" onClick={addLocation}>
            <Icon icon="md-pin-drop"/>
        </Fab>
    )
}


const MapPage = ({lat, lng, zoom}: { lat: number, lng: number, zoom: number }) => {
    return <Page renderFixed={() =>
        <MapContainer
            center={[lat, lng]}
            zoom={zoom}
            scrollWheelZoom={true}
            style={{height: '100%'}}
        >
            <OSMLayer/>
            <LocationCircle/>
            <MapContainerContent/>
            <AddLocationFab/>
        </MapContainer>
    }>
    </Page>;
}

export default MapPage
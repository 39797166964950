import {Splitter, SplitterContent, SplitterSide} from "react-onsenui";

const VerticalDivider = () => <hr style={{margin: "0 auto", width: "0", display: "inline-block"}}/>

export const DesktopColumnLayout = (props: { main: JSX.Element, detail: JSX.Element }) =>
    <Splitter>
            <SplitterContent>
                    {props.main}
            </SplitterContent>
            <VerticalDivider/>
            <SplitterSide
                side="right"
                width="38%"
            >
                    {props.detail}
            </SplitterSide>
    </Splitter>
import {List, ListHeader, ListItem} from "react-onsenui";
import {CollectionPoint} from "../../../data/firebase/CollectionPointCollection";
import {generateFromString} from "generate-avatar";
import {useNavigate} from "react-router-dom";
import StatusText from "../map/StatusText";
import {useT} from "../../translation/Translate";
import {R} from "../../translation/i18n";

type Props = { collectionPoints: CollectionPoint[] };
const CollectionPointList = ({collectionPoints}: Props) => {
    const t = useT()
    const navigate = useNavigate()

    const listItems = (feature: CollectionPoint, idx: number) =>
        <ListItem key={`collection-point-${idx}`} modifier="chevron"
                  onClick={() => navigate(`/collection-point/${feature.id}`)}>
            <div className="left"><img alt="" className="list-item__thumbnail"
                                       src={`data:image/svg+xml;utf8,${generateFromString(String(feature?.id))}`}/>
            </div>
            <div className="center">
                <StatusText feature={feature}/>
            </div>
            <div className="right">{feature?.properties?.preparer?.length}</div>
        </ListItem>;

    const listHeader = <ListHeader>{t(R.myCollectionPoints)}</ListHeader>;
    return (
        collectionPoints.length > 0
            ? <List
                dataSource={collectionPoints.sort((a, b) => a.properties.status - b.properties.status)}
                renderHeader={() => listHeader}
                renderRow={listItems}>
            </List>
            : <List renderHeader={() => listHeader}>
                <ListItem key="collection-points-empty">
                    <p>{t(R.goToMapAndSelectCollectionPoint)}</p>
                </ListItem>
            </List>
    )
}

export default CollectionPointList
import {FirestoreDataConverter} from "@firebase/firestore";
import {DocumentData} from "firebase/firestore";
import {Area} from "../AreaCollection";

export const areaConverter: FirestoreDataConverter<Area> = {
    fromFirestore(snapshot, options) {
        const feature = snapshot.data(options)

        return {
            ...feature,
            id: snapshot.id
        } as Area
    },
    toFirestore(modelObject): DocumentData {
        delete modelObject.id;
        return modelObject;
    }
}
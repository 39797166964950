import {Page, Tab, Tabbar} from "react-onsenui";
import {matchPath, useLocation} from "react-router";
import {Outlet, useNavigate} from "react-router-dom";
import React from "react";
import {useT} from "../translation/Translate";
import {R} from "../translation/i18n";
import {FIRST_PAGE_PATH} from "../router/Router";


export const BottomTapLayout = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const t = useT()

    const tabs = [
        {
            label: R.map,
            route: FIRST_PAGE_PATH,
            icon: "md-map",
        },
        {
            label: R.yours,
            route: "/item",
            icon: "md-view-list-alt",
        },
        {
            label: R.settings,
            route: "/settings",
            icon: "md-settings",
        }
    ].map((tab) => (
        {
            ...tab,
            label: t(tab.label)
        }
    ))

    let currentIndex = tabs.findIndex((tab) => matchPath(tab.route, location.pathname))

    if (currentIndex < 0) currentIndex = 0

    return <Page>
        <Tabbar
            position='bottom'
            index={currentIndex}
            onPreChange={({index}) => navigate(tabs[index].route)}
            renderTabs={(activeIndex, tabbar) =>
                tabs.map((tab, index) => {
                    const key = `tab-content-${index}`
                    return (
                        {
                            content: <Page key={key}>{index === currentIndex ? <Outlet/> : null}</Page>,
                            tab: <Tab key={tab.route} label={tab.label} icon={tab.icon}/>
                        }
                    );
                })
            }
        />
    </Page>
}

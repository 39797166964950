import {Icon} from "leaflet";
import {CollectionPoint} from "../../../data/firebase/CollectionPointCollection";
import {FeatureStatus} from "./StatusText";
import {useUserId} from "../../../data/firebase/provider/AuthProvider";
import {useTruckOfCurrentUser} from "../../../data/firebase/TruckCollection";
import {ResultStatus} from "../../../data/firebase/Database";

const GoldIcon = new Icon.Default({
    iconUrl: "/static/images/marker-icon-gold.png",
    iconRetinaUrl: "/static/images/marker-icon-2x-gold.png",
    shadowUrl: "/static/images/marker-shadow.png"
})

const BlueIcon = new Icon.Default({
    iconUrl: "/static/images/marker-icon-blue.png",
    iconRetinaUrl: "/static/images/marker-icon-2x-blue.png",
    shadowUrl: "/static/images/marker-shadow.png"
})

const GreenIcon = new Icon.Default({
    iconUrl: "/static/images/marker-icon-green.png",
    iconRetinaUrl: "/static/images/marker-icon-2x-green.png",
    shadowUrl: "/static/images/marker-shadow.png"
})

const VioletIcon = new Icon.Default({
    iconUrl: "/static/images/marker-icon-violet.png",
    iconRetinaUrl: "/static/images/marker-icon-2x-violet.png",
    shadowUrl: "/static/images/marker-shadow.png"
})

export const GreyIcon = new Icon.Default({
    iconUrl: "/static/images/marker-icon-grey.png",
    iconRetinaUrl: "/static/images/marker-icon-2x-grey.png",
    shadowUrl: "/static/images/marker-shadow.png"
})

export const BlackIcon = new Icon.Default({
    iconUrl: "/static/images/marker-icon-black.png",
    iconRetinaUrl: "/static/images/marker-icon-2x-black.png",
    shadowUrl: "/static/images/marker-shadow.png"
})

export const RedIcon = new Icon.Default({
    iconUrl: "/static/images/marker-icon-red.png",
    iconRetinaUrl: "/static/images/marker-icon-2x-red.png",
    shadowUrl: "/static/images/marker-shadow.png"
})

export const OrangeIcon = new Icon.Default({
    iconUrl: "/static/images/marker-icon-orange.png",
    iconRetinaUrl: "/static/images/marker-icon-2x-orange.png",
    shadowUrl: "/static/images/marker-shadow.png"
})

export const useStatusIcon = () => {
    const userId = useUserId();
    const truck = useTruckOfCurrentUser()

    return (feature: CollectionPoint) => {
        switch (feature.properties?.status) {
            case FeatureStatus.Planned:
                return GreyIcon
            case FeatureStatus.AssignedForPrepare:
                if (userId && feature.properties?.preparer
                    && feature.properties?.preparer?.includes(userId)) {
                    return VioletIcon
                } else {
                    return BlueIcon
                }
            case FeatureStatus.Prepared:
                return RedIcon
            case FeatureStatus.Ready:
                return OrangeIcon
            case FeatureStatus.AssignedForPick:
                if (truck.status === ResultStatus.Success && truck.data && feature.properties.truck === truck.data.id) {
                    return VioletIcon
                } else {
                    return GoldIcon
                }
            case FeatureStatus.Picked:
                return GreenIcon
            default:
                return GreyIcon
        }
    }
}
import {useT, useTFirebase} from "../../translation/Translate";
import React, {useCallback, useState} from "react";
import {sendPasswordResetEmail, signInWithEmailAndPassword} from "firebase/auth";
import {Button, Col, Icon, Input, ListItem, ListTitle} from "react-onsenui";
import {R} from "../../translation/i18n";
import {FirebaseError} from "@firebase/app";
import {useAuth} from "../../../data/firebase/provider/AuthProvider";
import {LOGIN_PAGE_PATH} from "../../router/Router";
import {DebugOptions} from "../../../debug/DebugOptions";

type LoginFormProps = { success: (message: string) => void, error: (e: string) => void }
const LoginForm = ({success, error}: LoginFormProps) => {
    const t = useT()
    const tFirebase = useTFirebase()
    const auth = useAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const sendFirebaseError = useCallback(
        (e: FirebaseError) => error(tFirebase(e)),
        [error, tFirebase]
    )

    const signIn = useCallback(() => {
        signInWithEmailAndPassword(auth, email.trim(), password)
            .then(() => {
            })
            .catch(sendFirebaseError)
    }, [auth, email, password, sendFirebaseError])

    const reset = useCallback(() => {
        sendPasswordResetEmail(auth, email.trim(), {url: `${window.location.origin}${LOGIN_PAGE_PATH}`})
            .then(() => {
                success(t(R.resetPasswordEmailSent))
            })
            .catch(sendFirebaseError)
    }, [auth, email, sendFirebaseError, success, t])

    const developmentSignIn = useCallback((user: string) => {
        signInWithEmailAndPassword(auth, `${user}@rolandgreim.de`, "123456")
            .then(() => {
            })
            .catch(sendFirebaseError)
    }, [auth, sendFirebaseError])

    const DevelopmentLogin = () =>
        DebugOptions.isDevelopment ? <>
            <ListTitle>Development Login as:</ListTitle>
            <ListItem>
                <Col>
                    <Button onClick={() => developmentSignIn("admin")}>Admin</Button>
                </Col>
                <Col>
                    <Button onClick={() => developmentSignIn("head")}>Head</Button>
                </Col>
                <Col>
                    <Button onClick={() => developmentSignIn("user")}>User</Button>
                </Col>
            </ListItem>
        </> : null

    return (
        <>
            <ListItem key="login-form-email">
                <div className="left">
                    <Icon icon={{default: 'md-email'}}/>
                </div>
                <div className="center">
                    <Input
                        value={email}
                        onChange={(event) => {
                            setEmail(event.target.value)
                        }}
                        placeholder={t(R.email)}
                        type="email"
                    />
                </div>
            </ListItem>
            <ListItem key="login-form-password">
                <div className="left">
                    <Icon icon={{default: 'md-key'}}/>
                </div>
                <div className="center">
                    <Input
                        value={password}
                        onChange={(event) => {
                            setPassword(event.target.value)
                        }}
                        placeholder={t(R.password)}
                        type="password"
                    />
                </div>
            </ListItem>
            <ListItem key="login-form-submit">
                <Button modifier="large" onClick={signIn}>{t(R.login)}</Button>
            </ListItem>
            <ListItem key="reset-password-button">
                <Button modifier="large--quiet" onClick={reset}>{t(R.resetPassword)}</Button>
            </ListItem>
            <DevelopmentLogin/>
        </>
    )
};

export default LoginForm
import React, {useCallback} from "react";
import {GoogleAuthProvider, signInWithCredential} from "firebase/auth";
import {FirebaseError} from "@firebase/app";
import {useAuth} from "../../../data/firebase/provider/AuthProvider";
import {CredentialResponse, GoogleLogin, GoogleOAuthProvider} from "@react-oauth/google";
import {useTFirebase} from "../../translation/Translate";


type LoginWithGoogleButtonProps = {
    success: (uid: string, nickname: string) => void,
    error: (e: string) => void
}
const LoginWithGoogleButton = ({success, error}: LoginWithGoogleButtonProps) => {
    const auth = useAuth();
    const tFirebase = useTFirebase();
    const clientId = "307492502050-s07ok4c9t0s4une6jt9ta2goujvo16es.apps.googleusercontent.com";

    const sendFirebaseError = (e: FirebaseError) => error(tFirebase(e))

    const login = useCallback(async (credentialResponse: CredentialResponse) => {
        if (credentialResponse.credential) {
            const credential = GoogleAuthProvider.credential(credentialResponse.credential);
            signInWithCredential(auth, credential)
                .then(async ({user: {uid, displayName}}) => {
                    if (displayName) {
                        success(uid, displayName)
                    }
                })
                .catch(sendFirebaseError)
        }
    }, [auth, error, success])

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <GoogleLogin
                onSuccess={login}
                onError={() => {
                    console.error('Login Failed');
                }}
                useOneTap
                auto_select
            />
        </GoogleOAuthProvider>
    )
}

export default LoginWithGoogleButton
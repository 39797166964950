import {useCallback, useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";

export const useLocalStorage = <T extends { id: string }>(key: string) => {
    const [data, setData] = useState<T[]>([]);

    useEffect(() => {
        const storageEventListener = (event: StorageEvent) => {
            if (event.storageArea === localStorage && event.key === key) {
                setData(event.newValue ? JSON.parse(event.newValue) : []);
            }
        };

        window.addEventListener("storage", storageEventListener);

        const items = localStorage.getItem(key);
        if (items) {
            setData(JSON.parse(items));
        }

        return () => {
            window.removeEventListener("storage", storageEventListener);
        };
    }, [key]);

    const updateItems = useCallback(
        (updatedValue: T[] | ((prevValue: T[]) => T[])) => {
            setData((prevValue) => {
                const newValue =
                    typeof updatedValue === "function"
                        ? (
                            updatedValue as (prevValue: T[]) => T[]
                        )(prevValue)
                        : updatedValue;

                if (newValue) {
                    localStorage.setItem(key, JSON.stringify(newValue));
                } else {
                    localStorage.removeItem(key);
                }

                return newValue || [];
            });
        },
        [key]
    );

    const addEntry = useCallback((newEntry: Omit<T, 'id'>) => {
        const entryWithId = {id: uuidv4(), ...newEntry} as T;
        updateItems((prevItems) => [...prevItems, entryWithId]);
    }, [updateItems]);

    const updateEntry = useCallback((id: string, updatedEntry: T) => {
        updateItems((prevItems) =>
            prevItems.map(item => (
                item.id === id ? updatedEntry : item
            )));
    }, [updateItems]);

    const removeEntry = useCallback((id: string) => {
        updateItems((prevItems) =>
            prevItems.filter(item => item.id !== id)
        );
    }, [updateItems]);

    return {data, addEntry, updateEntry, removeEntry} as const;
};

import {GeoPoint} from "firebase/firestore";
import {LatLng, LatLngBounds} from "leaflet";
import {Position} from "geojson";

export type MapPositionToGeoPoint<T> = T extends Position ? GeoPoint : T extends Position[][] ? GeoPoint[] : T extends Record<any, any> ? {
    [K in keyof T]: MapPositionToGeoPoint<T[K]>;
} : T;

export const toLatLng = (point: GeoPoint): LatLng => new LatLng(point.latitude, point.longitude)
export const toGeoPoint = (point: LatLng): GeoPoint => new GeoPoint(point.lat, point.lng)

export const calcBoundaries = (points: LatLng[]) => {
    const min = (a: number, b: number) => a < b ? a : b
    const max = (a: number, b: number) => a > b ? a : b

    if (points.length < 1) return null
    if (points.length === 1) return new LatLngBounds(points[0], points[0])

    const boundaries = points
        .reduce(
            (acc, b) =>
                [
                    new LatLng(min(acc[0].lat, b.lat), min(acc[0].lng, b.lng)),
                    new LatLng(max(acc[1].lat, b.lat), max(acc[1].lng, b.lng))
                ],
            [
                new LatLng(Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY),
                new LatLng(Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY)
            ]
        )
    return new LatLngBounds(boundaries[0], boundaries[1])
}
import {DesktopColumnLayout} from "../layout/DesktopColumnLayout";
import {LayerGroup, MapContainer, Marker, Popup} from "react-leaflet";
import OSMLayer from "../feature/map/OSMLayer";
import {
    CollectionPoint,
    CollectionPointSize,
    useAllCollectionPoints,
    useCollectionPointActions
} from "../../data/firebase/CollectionPointCollection";
import {ResultStatus} from "../../data/firebase/Database";
import ErrorPage from "./ErrorPage";
import LoadingPage from "./LoadingPage";
import {useCallback, useEffect, useMemo, useState} from "react";
import {Button, Checkbox, Input, List, ListHeader, ListItem, Page, Select, Switch, Toolbar} from "react-onsenui";
import {Route, useRoutes} from "../../data/localStorage/Route";
import {useTruckActions, useTrucks} from "../../data/firebase/TruckCollection";
import {Truck, TruckStatus} from "../../data/firebase/converter/TruckConverter";
import {useStatusIcon} from "../feature/map/Icon";
import {toLatLng} from "../../utils/Geo";
import StatusText, {FeatureStatus} from "../feature/map/StatusText";
import {useT} from "../translation/Translate";
import {R} from "../translation/i18n";
import {makeUnique} from "../../utils/List";

enum FeatureType {
    Truck,
    Route
}

const SizeText = ({collectionPoint}: { collectionPoint: CollectionPoint }) => {
    const t = useT();

    switch (collectionPoint.properties.size) {
        case CollectionPointSize.Large:
            return <p>{t(R.large)}</p>
        case CollectionPointSize.Medium:
            return <p>{t(R.medium)}</p>
        case CollectionPointSize.Small:
            return <p>{t(R.small)}</p>
        default:
            return null
    }
}

const CollectionPointPopup = ({feature, trucks, routes, onChangeRoute}: {
    feature: CollectionPoint,
    trucks: Truck[],
    routes: Route[],
    onChangeRoute: (collectionPointId: string, oldRouteId: string | null, newRouteId: string | null) => void,
}) => {
    const {setTruckAndStatus} = useCollectionPointActions();
    const selectItems = useMemo(() => routes.map(it => (
            {
                id: it.id,
                type: FeatureType.Route,
                description: it.description,
                selected: it.collectionPointIds.includes(feature.id)
            }
        )
    ), [feature, routes])

    const selected = useMemo(() => selectItems.find(it => it.selected), [selectItems])

    const onSelectItem = useCallback((oldId: string | null, newId: string) => {
        onChangeRoute(feature.id, oldId, newId)
    }, [feature, onChangeRoute]);

    return <Popup minWidth={230}>
        <StatusText feature={feature}/>
        <SizeText collectionPoint={feature}/>
        {feature.properties.status < FeatureStatus.AssignedForPick ? <Select
            value={selected?.id}
            onChange={(e) => onSelectItem(selected?.id || null, e.target.value)}
            disabled={feature.properties.status === FeatureStatus.Picked}
        >
            <option value={""}>No</option>
            {selectItems.map(it => <option value={it.id}>{it.description}</option>)}
        </Select> : <Button
            className="destructive"
            modifier="quiet"
            onClick={() => setTruckAndStatus(feature.id, null, FeatureStatus.Ready)}
        >Auf Abholbereit zurücksetzen</Button>}
    </Popup>;
};

const MarkerLayerGroup = (
    {
        collectionPoints,
        trucks,
        routes,
        selectedCollectionPoints,
        onChangeRoute,
    }: {
        collectionPoints: CollectionPoint[],
        trucks: Truck[],
        routes: Route[],
        selectedCollectionPoints: string[],
        onChangeRoute: (collectionPointId: string, oldRouteId: string | null, newRouteId: string | null) => void,
    }
) => {
    const icon = useStatusIcon()

    const noneSelected = selectedCollectionPoints.length === 0
    return (
        <LayerGroup>
            {collectionPoints.map(feature => {
                    const latLng = toLatLng(feature.geometry.coordinates)
                    return <Marker
                        key={feature.id}
                        position={latLng}
                        icon={icon(feature)}
                        opacity={noneSelected || selectedCollectionPoints.includes(feature.id) ? 1.0 : 0.5}
                        riseOnHover={true}
                    >
                        <CollectionPointPopup
                            feature={feature}
                            trucks={trucks}
                            routes={routes}
                            onChangeRoute={onChangeRoute}
                        />
                    </Marker>
                }
            )}
        </LayerGroup>
    )
};

const Map = (
    {
        collectionPoints,
        trucks,
        routes,
        selectedCollectionPoints,
        onChangeRoute,
    }: {
        collectionPoints: CollectionPoint[]
        trucks: Truck[],
        routes: Route[],
        selectedCollectionPoints: string[],
        onChangeRoute: (collectionPointId: string, oldRouteId: string | null, newRouteId: string | null) => void,
    }
) => {
    return <MapContainer
        center={[49.722, 11.078]}
        zoom={13}
        scrollWheelZoom={true}
        style={{height: '100%'}}
    >
        <MarkerLayerGroup
            collectionPoints={collectionPoints}
            trucks={trucks}
            routes={routes}
            selectedCollectionPoints={selectedCollectionPoints}
            onChangeRoute={onChangeRoute}
        />
        <OSMLayer/>
    </MapContainer>;
};

const Assign = (
    {
        trucks,
        routes,
        selectedTrucks,
        selectedRoutes,
        onSelectRouteOrTruck,
        addRoute,
        addTruck,
        deleteRoute,
        updateRouteDescription,
        updateTruckName,
        updateTruckStatus,
        assignRouteToTruck
    }: {
        trucks: Truck[],
        routes: Route[],
        selectedTrucks: string[],
        selectedRoutes: string[],
        onSelectRouteOrTruck: (id: string, type: FeatureType) => void,
        addRoute: () => void,
        addTruck: () => void,
        deleteRoute: (id: string) => void,
        updateRouteDescription: (route: Route, description: string) => void,
        updateTruckName: (id: string, description: string) => void,
        updateTruckStatus: (id: string, status: TruckStatus) => void,
        assignRouteToTruck: (routeId: string, truckId: string) => void
    }
) => {
    const t = useT();
    const oneRoute = selectedTrucks.length === 0 && selectedRoutes.length === 1 ? selectedRoutes[0] : null
    const oneTruck = selectedRoutes.length === 1 && selectedTrucks.length === 1
    return (
        <Page renderToolbar={() => <Toolbar>
            <div className="left">
                <Button
                    className={"destructive"}
                    modifier={"quiet"}
                    disabled={!oneRoute}
                    onClick={() => {
                        if (oneRoute) deleteRoute(oneRoute)
                    }}>{t(R.delete)}</Button>
            </div>
            <div className="center">
                {t(R.management)}
            </div>
            <div className="right">
                <Button
                    modifier={"quiet"}
                    disabled={!oneTruck}
                    onClick={() => {
                        const route = selectedRoutes[0]
                        const truck = selectedTrucks[0]
                        if (route && truck) assignRouteToTruck(route, truck)
                    }}>{t(R.add)}</Button>
            </div>
        </Toolbar>}>
            <List>

                <ListHeader>Routes</ListHeader>
                {routes.map((route) =>
                    <ListItem key={`route-${route.id}`} tappable>
                        <div className="left">
                            <Checkbox
                                inputId={`route-checkbox-${route.id}`}
                                checked={selectedRoutes.includes(route.id)}
                                onChange={(e) => onSelectRouteOrTruck(route.id, FeatureType.Route)}
                            />
                        </div>
                        <div className="center">
                            <Input value={route.description}
                                   onChange={(e) => updateRouteDescription(route, e.target.value)}/>
                        </div>
                        <div className="right">
                            <p>({route.collectionPointIds.length})</p>
                        </div>
                    </ListItem>
                )}

                <ListItem key="add-route-button">
                    <Button
                        onClick={addRoute}
                        modifier="large--quiet"
                    >➕ {t(R.add)}</Button>
                </ListItem>

                <ListHeader>Trucks</ListHeader>
                {trucks.map((truck) =>
                    <ListItem key={`truck-${truck.id}`} tappable>
                        <div className="left">
                            <Checkbox
                                inputId={`truck-checkbox-${truck.id}`}
                                checked={selectedTrucks.includes(truck.id)}
                                onChange={(e) => onSelectRouteOrTruck(truck.id, FeatureType.Truck)}
                            />
                        </div>
                        <div className="center">
                            <Input
                                key={`truck-name-input-${truck.id}`}
                                value={truck.name}
                                onChange={(e) => {
                                    updateTruckName(truck.id, e.target.value)
                                }}
                            />
                        </div>
                        <div className="right">
                            <Switch
                                key={`truck-status-toggle-${truck.id}`}
                                checked={truck.status === TruckStatus.Open}
                                onChange={it => {
                                    const newStatus = truck.status === TruckStatus.Open ? TruckStatus.Closed : TruckStatus.Open;
                                    updateTruckStatus(truck.id, newStatus);
                                }}
                            />
                        </div>
                    </ListItem>
                )}
                <ListItem key="add-truck-button">
                    <Button
                        onClick={addTruck}
                        modifier="large--quiet"
                    >➕ {t(R.add)}</Button>
                </ListItem>
            </List>
        </Page>
    )
}

export const TruckAssignPage = () => {
    const {status: collectionPointsStatus, data: collectionPointsData} = useAllCollectionPoints();
    const {status: trucksStatus, data: trucksData} = useTrucks();
    const {data: routes, addEntry, updateEntry, removeEntry} = useRoutes();
    const [selectedRoutes, setSelectedRoutes] = useState<string[]>([])
    const [selectedTrucks, setSelectedTrucks] = useState<string[]>([])
    const {addTruck, updateTruckName, updateTruckStatus} = useTruckActions();
    const {setTruckAndStatus} = useCollectionPointActions();

    useEffect(() => {
        if (collectionPointsStatus === ResultStatus.Success) {
            const assignedAndPickedCollectionPoints = collectionPointsData
                .filter(it => it.properties.status > FeatureStatus.Ready)
                .map(it => it.id);
            routes
                .forEach(route => {
                    if (route.collectionPointIds.some(it => assignedAndPickedCollectionPoints.includes(it))) {
                        updateEntry(route.id, {
                            ...route,
                            collectionPointIds: route.collectionPointIds.filter(it => !assignedAndPickedCollectionPoints.includes(it))
                        })
                    }
                })
        }
    }, [collectionPointsData, collectionPointsStatus, routes, updateEntry])

    const onSelectRouteOrTruck = useCallback((id: string, type: FeatureType) => {
        if (type === FeatureType.Route) {
            if (selectedRoutes.includes(id)) {
                setSelectedRoutes(selectedRoutes.filter(it => it !== id))
            } else {
                setSelectedRoutes(makeUnique([...selectedRoutes, id]))
            }
        } else if (type === FeatureType.Truck) {
            if (selectedTrucks.includes(id)) {
                setSelectedTrucks(selectedTrucks.filter((
                    it => it !== id
                )))
            } else {
                setSelectedTrucks(makeUnique([...selectedTrucks, id]))
            }
        }
    }, [selectedRoutes, selectedTrucks]);

    const addRoute = useCallback(() => {
        addEntry({description: `Route ${routes.length + 1}`, collectionPointIds: []})
    }, [addEntry, routes]);

    const deleteRoute = useCallback((id: string) => {
        removeEntry(id)
        setSelectedRoutes(selectedRoutes.filter(it => it !== id))
    }, [removeEntry, selectedRoutes]);

    const updateRouteDescription = useCallback((route: Route, description: string) => {
        updateEntry(route.id, {...route, description: description})
    }, [updateEntry]);

    const onChangeRoute = useCallback((collectionPointId: string, oldRouteId: string | null, newRouteId: string | null) => {
        const oldRoute = routes.find(it => it.id === oldRouteId)
        const newRoute = routes.find(it => it.id === newRouteId)

        if (oldRoute) {
            oldRoute.collectionPointIds = oldRoute.collectionPointIds.filter(it => it !== collectionPointId)
            updateEntry(oldRoute.id, oldRoute)
        }
        if (newRoute) {
            newRoute.collectionPointIds = makeUnique([...newRoute.collectionPointIds, collectionPointId])
            updateEntry(newRoute.id, newRoute)
        }
    }, [routes, updateEntry])

    const assignRouteToTruck = useCallback((routeId: string, truckId: string) => {
        const route = routes.find(it => it.id === routeId)

        if (route) {
            route.collectionPointIds.forEach(collectionPointId => {
                setTruckAndStatus(collectionPointId, truckId, FeatureStatus.AssignedForPick)
            })
            deleteRoute(routeId)
        }
    }, [deleteRoute, routes, setTruckAndStatus]);

    if (collectionPointsStatus === ResultStatus.Error) {
        return <ErrorPage errorMsg={collectionPointsData.message}/>
    }

    if (trucksStatus === ResultStatus.Error) {
        return <ErrorPage errorMsg={trucksData.message}/>
    }

    if (collectionPointsStatus === ResultStatus.Loading || trucksStatus === ResultStatus.Loading) {
        return <LoadingPage/>
    }

    const selectedCollectionPoints = routes
        .filter(route => selectedRoutes.includes(route.id))
        .flatMap(route => route.collectionPointIds)
        .concat(
            collectionPointsData
                .filter(it => selectedTrucks.includes(it.properties.truck || ""))
                .map(it => it.id)
        )

    return (
        <DesktopColumnLayout main={
            <Page>
                <Map
                    collectionPoints={collectionPointsData}
                    trucks={trucksData}
                    routes={routes}
                    selectedCollectionPoints={selectedCollectionPoints}
                    onChangeRoute={onChangeRoute}
                />
            </Page>
        } detail={
            <Assign
                trucks={trucksData}
                routes={routes}
                selectedRoutes={selectedRoutes}
                selectedTrucks={selectedTrucks}
                onSelectRouteOrTruck={onSelectRouteOrTruck}
                addRoute={addRoute}
                deleteRoute={deleteRoute}
                updateRouteDescription={updateRouteDescription}
                addTruck={addTruck}
                updateTruckName={updateTruckName}
                updateTruckStatus={updateTruckStatus}
                assignRouteToTruck={assignRouteToTruck}
            />
        }/>
    )
}
import {
    CollectionDefinition,
    fromLoadingHookAndMap,
    useCollectionRef,
    useCollectionState,
    useDocState
} from "./Database";
import {Truck, truckConverter, TruckStatus} from "./converter/TruckConverter";
import {useCallback} from "react";
import {addDoc, arrayRemove, arrayUnion, doc, limit, query, updateDoc, where} from "firebase/firestore";
import {useUserId} from "./provider/AuthProvider";
import {useCollectionData} from "react-firebase-hooks/firestore";

const PROPERTIES = {
    STATUS: "status",
    NAME: 'name',
    CREW: "crew"
};

const definition: CollectionDefinition<Truck> = {
    id: 'truck',
    converter: truckConverter
}

export const useTrucks = () => useCollectionState(definition);

export const useTruck = (id: string) => useDocState(definition, id);

export const useTruckRef = () => useCollectionRef(definition);

export function useTruckOfCurrentUser() {
    const userId = useUserId();
    const trucksCollection = useTruckRef();
    const whereCurrentUser = where(PROPERTIES.CREW, "array-contains", userId)

    const trucksQuery = query(trucksCollection, whereCurrentUser, limit(1))
    const res = useCollectionData(trucksQuery);
    return fromLoadingHookAndMap(res, (value) => value[0])
}

export const useTruckActions = () => {
    const collectionRef = useTruckRef();
    const userId = useUserId();

    const addTruck = useCallback(async () => {
        const data: Omit<Truck, "id"> = {
            name: "new Truck",
            status: TruckStatus.Closed,
            crew: []
        }
        await addDoc(collectionRef, data)
    }, [collectionRef]);

    const updateTruckStatus = useCallback(async (truckId: string, status: TruckStatus) => {
        await updateDoc(
            doc(collectionRef, truckId),
            PROPERTIES.STATUS,
            status
        )
    }, [collectionRef]);

    const updateTruckName = useCallback(async (truckId: string, name: string) => {
        await updateDoc(
            doc(collectionRef, truckId),
            PROPERTIES.NAME,
            name
        )
    }, [collectionRef]);

    const assignTruck = useCallback(async (truckId: string) => {
        await updateDoc(
            doc(collectionRef, truckId),
            PROPERTIES.CREW,
            arrayUnion(userId)
        )
    }, [collectionRef, userId]);

    const dissociateTruck = useCallback(async (truckId: string) => {
        await updateDoc(
            doc(collectionRef, truckId),
            PROPERTIES.CREW,
            arrayRemove(userId)
        )
    }, [collectionRef, userId]);

    return {addTruck, updateTruckStatus, updateTruckName, assignTruck, dissociateTruck}
}
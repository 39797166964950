import {FeatureStatus} from "./StatusText";
import {R} from "../../translation/i18n";
import {useT} from "../../translation/Translate";

const useStatusMapper = () => {
    const t = useT()
    return {
        statusToString: (status: FeatureStatus) => {
            switch (status) {
                case FeatureStatus.Planned:
                    return t(R.planned)
                case FeatureStatus.AssignedForPrepare:
                    return t(R.assignedForPrepare)
                case FeatureStatus.Prepared:
                    return t(R.prepared)
                case FeatureStatus.Ready:
                    return t(R.ready)
                case FeatureStatus.AssignedForPick:
                    return t(R.assignedForPick)
                case FeatureStatus.Picked:
                    return t(R.picked)
                case FeatureStatus.Unknown:
                    return t(R.unknown)
            }
        }
    }
}

export default useStatusMapper
import {Area, useAreaStatusHandler} from "../../../data/firebase/AreaCollection";
import {ButtonModifier} from "../onsenUi/ButtonModifier";
import {useT} from "../../translation/Translate";
import {useCallback} from "react";
import {R} from "../../translation/i18n";
import {FeatureStatus} from "../map/StatusText";
import {Button, Row} from "react-onsenui";
import {useNavigate} from "react-router-dom";
import {UserRoles, useUserRole} from "../../../data/firebase/UserCollection";
import {useUserId} from "../../../data/firebase/provider/AuthProvider";
import {ResultStatus} from "../../../data/firebase/Database";

type Props = { feature: Area, modifier: ButtonModifier, details: boolean }
export const AreaStatusActionButton = ({feature, modifier, details}: Props) => {
    const t = useT()
    const {take, release, setStatus} = useAreaStatusHandler()
    const userId = useUserId()
    const {status: userRoleStatus, data: userRole} = useUserRole()
    const navigate = useNavigate();

    const getActions = useCallback(({head}: UserRoles) => {
        const isAssigned = (feature: Area, userId: string) =>
            feature.properties.collectors
            && feature.properties.collectors.includes(userId)

        const planned = feature.properties.status === FeatureStatus.Planned
        const assigned = isAssigned(feature, userId)
        let actions = []

        if (planned) {
            if (assigned) {
                actions.push({label: R.release, action: () => release(feature.id), destructive: true})
            } else {
                actions.push({label: R.take, action: () => take(feature.id)})
            }
        }

        if (details && (
            head || assigned
        )) {
            actions.push({label: R.details, action: async () => navigate("/area/" + feature.id)})
        }

        if (planned && head) {
            actions.push({label: R.finalize, action: () => setStatus(feature.id, FeatureStatus.AssignedForPrepare)})
        }

        return actions
    }, [feature, userId, details, release, take, navigate, setStatus])

    if (userRoleStatus !== ResultStatus.Success) return null

    const actions = getActions(userRole)

    return (
        actions ? <Row>
            {actions.map(({label, action, destructive}, idx) => (
                <Button
                    key={"area-action-" + idx}
                    className={destructive ? "destructive" : undefined}
                    modifier={modifier}
                    onClick={action}
                    disabled={!actions}
                >
                    {t(label)}
                </Button>
            ))}
        </Row> : null
    )
}
import React from "react";
import {Icon, Input, List, ListHeader, ListItem, Page, Select, Toolbar} from "react-onsenui";
import {useNickname, useUserRole} from "../../data/firebase/UserCollection";
import {useT} from "../translation/Translate";
import {R} from "../translation/i18n";
import LogoutButton from "../feature/auth/LogoutButton";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ResultStatus} from "../../data/firebase/Database";
import LoadingPage from "./LoadingPage";
import ErrorPage from "./ErrorPage";

const SettingsToolbar = () => {
    const t = useT()

    return <Toolbar>
        <div className="center">
            {t(R.settings)}
        </div>
    </Toolbar>
}

const HeadListItems = () => {
    const navigate = useNavigate();
    return (
        <>
            <ListHeader>Zentrale</ListHeader>
            <ListItem key={`area-list-page-link`} modifier="chevron"
                      onClick={() => navigate("/head/area")}>
                <p>Gebiet Verwaltung</p>
            </ListItem>
            <ListItem key={`truck-page-link`} modifier="chevron"
                      onClick={() => navigate("/head/truck")}>
                <p>LKW Verwaltung</p>
            </ListItem>
        </>
    )
}

const AdminListItems = () => {
    const navigate = useNavigate();
    return (
        <>
            <ListHeader>Administrator</ListHeader>
            <ListItem key={`debug-page-link`} modifier="chevron"
                      onClick={() => navigate("/admin/data")}>
                <p>Reset Data</p>
            </ListItem>
        </>
    );
}

const SettingsPage = () => {
    const {i18n} = useTranslation()
    const t = useT();
    const {status, data} = useUserRole();
    const {nickname, setNickname} = useNickname()

    if (status === ResultStatus.Loading) {
        return <LoadingPage/>
    }

    if (status === ResultStatus.Error) {
        return <ErrorPage errorMsg={data.message}/>
    }

    const {head, admin} = data

    return <Page renderToolbar={SettingsToolbar}>
        <List>
            <ListItem key="settings-nickname">
                <div className="left">
                    <Icon icon={{default: 'md-face'}}/>
                </div>
                <div className="center">
                    <Input
                        value={nickname}
                        onChange={async (event) => {
                            await setNickname(event.target.value)
                        }}
                        placeholder={t(R.nickname)}
                        type="text"
                    />
                </div>
            </ListItem>
            <ListItem key="settings-lang">
                <Select value={i18n.language} onChange={(e) => i18n.changeLanguage(e.target.value)}>
                    <option value="de">🇩🇪 {t(R.german)}</option>
                    <option value="en">🇬🇧 {t(R.english)}</option>
                </Select>
            </ListItem>
            <ListItem key="settings-whatsapp">
                <p>Sende uns dein bestes Foto der CBA bei <a
                    href="https://chat.whatsapp.com/KIieCyfIYJvL5ZDvtJw4Vt">Whatsapp</a> oder <a
                    href="https://signal.group/#CjQKIPSYY7bGegxc-fEgM5bpAW6ppJusgwlgt2OehciieOm7EhDM8Wg-mvjIrcMn5c5-huEd">Signal</a> und
                    erhalte Informationen vom CVJM Forchheim :)
                </p>
            </ListItem>
            {head ? <HeadListItems/> : null}
            {admin ? <AdminListItems/> : null}
            <ListItem key="settings-logout">
                <LogoutButton/>
            </ListItem>
        </List>
    </Page>
}

export default SettingsPage;
import {FirestoreDataConverter} from "@firebase/firestore";
import {DocumentData} from "firebase/firestore";

export type UserProfile = {
    id: string,
    nickname: string,
}

export const userProfileConverter: FirestoreDataConverter<UserProfile> = {
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options);
        return {
            nickname: "",
            ...data,
            id: snapshot.id,
        }
    },
    toFirestore(modelObject): DocumentData {
        delete modelObject.id;
        return modelObject;
    }
}
import {FirestoreDataConverter} from "@firebase/firestore";
import {DocumentData} from "firebase/firestore";

export enum TruckStatus {
    Closed,
    Open
}

export type Truck = {
    id: string,
    name: string,
    status: TruckStatus,
    crew: string[]
}

export const truckConverter: FirestoreDataConverter<Truck> = {
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options);
        return {
            name: "",
            status: TruckStatus.Closed,
            ...data,
            id: snapshot.id,
        }
    },
    toFirestore(modelObject): DocumentData {
        delete modelObject.id;
        return modelObject;
    }
}
import {Button, List, ListHeader, ListItem, Select} from "react-onsenui";
import {useT} from "../../translation/Translate";
import {R} from "../../translation/i18n";
import {Truck, TruckStatus} from "../../../data/firebase/converter/TruckConverter";
import {useUserId} from "../../../data/firebase/provider/AuthProvider";
import {useTruckActions} from "../../../data/firebase/TruckCollection";
import {useState} from "react";

export const TruckList = (
    {trucks}: { trucks: Truck[] }
) => {
    const t = useT();
    const userId = useUserId();
    const {assignTruck, dissociateTruck} = useTruckActions();
    const [selectedTruck, setSelectedTruck] = useState<string | undefined>()

    const myTruck = trucks.find(truck => truck.crew?.includes(userId));
    const openTrucks = trucks.filter(truck => truck.status === TruckStatus.Open)

    const renderMyTruck = (truck: Truck) => <ListItem key="my-truck">
        <div className="center">
            <p>{truck.name}</p>
        </div>
        <div className="right">
            <Button
                className={"destructive"}
                onClick={() => dissociateTruck(truck.id)}
                modifier="large--quiet">
                ❌ {t(R.delete)}
            </Button>
        </div>
    </ListItem>

    const renderOpenTruckSelect = (trucks: Truck[]) => {
        if (!selectedTruck) {
            setSelectedTruck(trucks[0].id)
        }
        return <ListItem key="open-truck-select">
            <div className="center">
                <Select
                    value={selectedTruck}
                    onChange={(e) => setSelectedTruck(e.target.value)}
                >
                    {trucks.map(truck => <option value={truck.id}>
                        {truck.name}
                    </option>)}
                </Select>
            </div>
            <div className="right">
                <Button
                    modifier="quiet"
                    onClick={() => {
                        if (selectedTruck) assignTruck(selectedTruck)
                    }}
                >➕ {t(R.add)}</Button>
            </div>
        </ListItem>;
    }

    const renderNoTruckOpen = () => <ListItem key="no-trucks">
        <p>{t(R.noTrucksOpen)}</p>
    </ListItem>

    return <List>
        <ListHeader key="truck-header">{t(R.myTruck)}</ListHeader>
        {myTruck ? renderMyTruck(myTruck) : openTrucks.length > 0 ? renderOpenTruckSelect(openTrucks) : renderNoTruckOpen()}
    </List>
}
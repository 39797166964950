import {useT} from "../../translation/Translate";
import React, {useCallback} from "react";
import {signOut} from "firebase/auth";
import {Button} from "react-onsenui";
import {R} from "../../translation/i18n";
import {useNavigate} from "react-router-dom";
import {LOGIN_PAGE_PATH} from "../../router/Router";
import {useAuth} from "../../../data/firebase/provider/AuthProvider";

const LogoutButton = () => {
    const t = useT()
    const auth = useAuth()
    const navigate = useNavigate();
    const logout = useCallback(async () => {
        signOut(auth).then(() => navigate(LOGIN_PAGE_PATH))
    }, [auth, navigate])
    return <Button
        className={"destructive"}
        onClick={logout}
        modifier="large--quiet">
        {t(R.logout)}
    </Button>
}

export default LogoutButton
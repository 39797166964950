import {CollectionDefinition, fromLoadingHook, useCollectionRef, useCollectionState, useDocState} from "./Database";
import {arrayRemove, arrayUnion, doc, orderBy, query, updateDoc, where} from "firebase/firestore";
import {Feature, Polygon} from "geojson";
import {FeatureStatus} from "../../view/feature/map/StatusText";
import {useCallback} from "react";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {useUserId} from "./provider/AuthProvider";
import {areaConverter} from "./converter/AreaConverter";
import {MapPositionToGeoPoint} from "../../utils/Geo";

const PROPERTIES = {
    NUMBER: "properties.number",
    STATUS: "properties.status",
    COLLECTORS: 'properties.collectors',
    ADDITIONAL_COLLECTORS: 'properties.additionalCollectors',
    CONTACT: "properties.contact"
};

export type AreaProperties = {
    collectors: string[],
    additionalCollectors: string[],
    name: string,
    number: number,
    status: FeatureStatus,
    contact?: string,
    preparer?: string[],
}

export interface Area extends MapPositionToGeoPoint<Feature<Polygon, AreaProperties>> {
    id: string
}

const definition: CollectionDefinition<Area> = {
    id: 'area',
    converter: areaConverter
}

export const useAreas = () => useCollectionState(definition, orderBy(PROPERTIES.NUMBER));

export const useArea = (id: string) => useDocState(definition, id);

export const useAreaCollectionRef = () => useCollectionRef(definition);

export function useAreasOfCurrentUser() {
    const userId = useUserId();
    const collectionRef = useAreaCollectionRef();
    const whereCurrentUser = where(PROPERTIES.COLLECTORS, "array-contains", userId)

    const areasQuery = query(collectionRef, whereCurrentUser)
    const res = useCollectionData(areasQuery);
    return fromLoadingHook(res)
}

export const useAreaStatusHandler = () => {
    const collectionRef = useAreaCollectionRef();
    const userId = useUserId();

    const addCollector = useCallback(async (areaId: string, userId: string) => {
        await updateDoc(
            doc(collectionRef, areaId),
            PROPERTIES.COLLECTORS,
            arrayUnion(userId)
        )
    }, [collectionRef])

    const removeCollector = useCallback(async (areaId: string, userId: string) => {
        await updateDoc(
            doc(collectionRef, areaId),
            PROPERTIES.COLLECTORS,
            arrayRemove(userId)
        )
    }, [collectionRef])

    const addAdditionalCollector = useCallback(async (areaId: string, name: string) => {
        await updateDoc(
            doc(collectionRef, areaId),
            PROPERTIES.ADDITIONAL_COLLECTORS,
            arrayUnion(name)
        )
    }, [collectionRef])

    const removeAdditionalCollector = useCallback(async (areaId: string, name: string) => {
        await updateDoc(
            doc(collectionRef, areaId),
            PROPERTIES.ADDITIONAL_COLLECTORS,
            arrayRemove(name)
        )
    }, [collectionRef])

    const take = useCallback(async (id: string) => {
        await addCollector(id, userId)
    }, [addCollector, userId])

    const release = useCallback(async (id: string) => {
        await removeCollector(id, userId)
    }, [removeCollector, userId])

    const setStatus = useCallback(async (id: string, status: FeatureStatus) => {
        await updateDoc(
            doc(collectionRef, id),
            PROPERTIES.STATUS,
            status
        )
    }, [collectionRef])

    const setContact = useCallback(async (id: string, contact: string) => {
        await updateDoc(
            doc(collectionRef, id),
            PROPERTIES.CONTACT,
            contact
        )
    }, [collectionRef]);

    return {
        take,
        release,
        addCollector,
        removeCollector,
        addAdditionalCollector,
        removeAdditionalCollector,
        setStatus,
        setContact,
    }
};
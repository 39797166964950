import {
    CollectionPoint,
    useAdditionalCollectionPoints,
    useCollectionPoints
} from "../../../data/firebase/CollectionPointCollection";
import {LayerGroup, Marker} from "react-leaflet";
import {useStatusIcon} from "../map/Icon";
import CollectionPointPopup from "./CollectionPointPopup";
import {ResultStatus} from "../../../data/firebase/Database";
import {toLatLng} from "../../../utils/Geo";

function MarkerLayerGroup(collectionPoints: CollectionPoint[]) {
    const icon = useStatusIcon()

    return (
        <LayerGroup>
            {collectionPoints.map(feature => {
                const latLng = toLatLng(feature.geometry.coordinates)
                    return <Marker
                        key={feature.id}
                        position={latLng}
                        icon={icon(feature)}
                    >
                        <CollectionPointPopup collectionPoint={feature}/>
                    </Marker>
                }
            )}
        </LayerGroup>
    )
}

export const CollectionPointLayerGroup = () => {
    const {status, data} = useCollectionPoints();
    return MarkerLayerGroup(status === ResultStatus.Success ? data : []);
}

export const AdditionalCollectionPointLayerGroup = () => {
    const {status, data} = useAdditionalCollectionPoints();
    return MarkerLayerGroup(status === ResultStatus.Success ? data : []);
}

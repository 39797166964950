import React from "react";
import {Col, Page, Toolbar} from "react-onsenui";
import CollectionPointList from "../feature/collectionPoint/CollectionPointList";
import {useT} from "../translation/Translate";
import {R} from "../translation/i18n";
import AreaList from "../feature/area/AreaList";
import {useCollectionPointsOfCurrentUser} from "../../data/firebase/CollectionPointCollection";
import {useAreasOfCurrentUser} from "../../data/firebase/AreaCollection";
import {ResultStatus} from "../../data/firebase/Database";
import ErrorPage from "./ErrorPage";
import LoadingPage from "./LoadingPage";
import {useTrucks} from "../../data/firebase/TruckCollection";
import {TruckList} from "../feature/truck/TruckList";

const ItemsToolbar = () => {
    const t = useT()

    return <Toolbar>
        <div className="center">
            {t(R.yours)}
        </div>
    </Toolbar>
}

const ItemPage = () => {
    const {status: areasStatus, data: areasData} = useAreasOfCurrentUser();
    const {status: collectionPointsStatus, data: collectionPointsData} = useCollectionPointsOfCurrentUser();
    const {status: trucksStatus, data: trucksData} = useTrucks();

    if (areasStatus === ResultStatus.Error) {
        return <ErrorPage errorMsg={areasData.message}/>
    }
    if (collectionPointsStatus === ResultStatus.Error) {
        return <ErrorPage errorMsg={collectionPointsData.message}/>
    }
    if (trucksStatus === ResultStatus.Error) {
        return <ErrorPage errorMsg={trucksData.message}/>
    }
    if (areasStatus === ResultStatus.Loading || collectionPointsStatus === ResultStatus.Loading || trucksStatus === ResultStatus.Loading) {
        return <LoadingPage/>
    }

    return <Page renderToolbar={ItemsToolbar}>
        <Col>
            <AreaList areas={areasData}/>
            <CollectionPointList collectionPoints={collectionPointsData}/>
            <TruckList trucks={trucksData}/>
        </Col>
    </Page>;
}

export default ItemPage;
import React from "react";
import {FirebaseAppProvider} from "./FirebaseAppProvider";
import {FirestoreProvider} from "./FirestoreProvider";
import {AuthProvider} from "./AuthProvider";

export function FirebaseProviders({children}: Props) {
    // any child components will be able to use `useUser`, `useDatabaseObjectData`, etc
    return (
        <FirebaseAppProvider>
            <AuthProvider>
                <FirestoreProvider>
                    {children}
                </FirestoreProvider>
            </AuthProvider>
        </FirebaseAppProvider>
    );
}

type Props = {
    children: JSX.Element
};
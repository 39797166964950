import {Circle, useMapEvents} from "react-leaflet";
import {LatLng} from "leaflet";
import {useEffect, useState} from "react";

export const LocationCircle = ({flyTo = () => false}: { flyTo?: (pos: LatLng) => boolean }) => {
    const [position, setPosition] = useState<{ latlng: LatLng, accuracy: number } | undefined>()
    const map = useMapEvents({
        locationfound(e) {
            const latlng = e.latlng;
            setPosition({latlng, accuracy: e.accuracy})
            if (flyTo(latlng)) map.flyTo(latlng, 18)
        },
    })

    useEffect(() => {
        map.locate({
            enableHighAccuracy: true,
            watch: true
        })
    }, [map])

    return (
        position ?
            <Circle
                center={position.latlng}
                radius={position.accuracy}
                color={"LightSkyBlue"}
            /> : null
    )
}
import {List, ListItem, ListTitle, Page, Segment, Toast} from "react-onsenui";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useT} from "../translation/Translate";
import {R} from "../translation/i18n";
import LoginForm from "../feature/auth/LoginForm";
import RegisterForm from "../feature/auth/RegisterForm";
import LoginWithGoogleButton from "../feature/auth/LoginWithGoogleButton";
import {FIRST_PAGE_PATH, VERIFY_EMAIL_PAGE_PATH} from "../router/Router";
import {useUser} from "../../data/firebase/provider/AuthProvider";
import {useNicknameAnonymous} from "../../data/firebase/UserCollection";
import {ResultStatus} from "../../data/firebase/Database";


const LoginPage = () => {
    const t = useT()
    const {status: userStatus, data: user} = useUser()
    const [state, setstate] = useState({tabIndex: 0, toast: ""})
    const {setNickname} = useNicknameAnonymous();
    const navigate = useNavigate()

    if (userStatus === ResultStatus.Success && user) {
        if (user.emailVerified) {
            navigate(FIRST_PAGE_PATH)
        } else {
            navigate(VERIFY_EMAIL_PAGE_PATH)
        }
    }

    const loginSuccess = (success: string) => {
        setstate({
            ...state,
            toast: success
        })
        setTimeout(() => setstate({...state, toast: ""}), 4000)
    }

    const registerSuccess = async (userId: string, nickname: string) => {
        await setNickname(userId, nickname)
    }

    const error = (error: string) => {
        console.error(error)

        setstate({
            ...state,
            toast: error
        })
        setTimeout(() => setstate({...state, toast: ""}), 4000)
    }

    const selectTab = (i: number) => () => setstate({...state, tabIndex: i});

    const CbaHeader = () => {
        return (
            <header style={{
                backgroundColor: '#2ecc71',
                color: '#fff',
                textAlign: 'center',
                padding: '20px',
                overflow: 'hidden',
                fontSize: '1.5em', // Beispiel für eine kleinere Schriftgröße für mobile Geräte
            }}>
                <h1>Willkommen bei der Christbaumaktion</h1>
            </header>
        );
    };

    return (
        <Page>
            <CbaHeader/>
            <List>
                <ListItem key="login-with-google-button">
                    <LoginWithGoogleButton success={registerSuccess} error={error}/>
                </ListItem>
            </List>
            <ListTitle>{t(R.or)}</ListTitle>
            <List>
                <ListItem>
                    <Segment style={{width: '100%', margin: '4px 12px'}} index={state.tabIndex}>
                        <button onClick={selectTab(0)}>{t(R.login)}</button>
                        <button onClick={selectTab(1)}>{t(R.register)}</button>
                    </Segment>
                </ListItem>
                {[
                    <LoginForm success={loginSuccess} error={error}/>,
                    <RegisterForm success={registerSuccess} error={error}/>
                ][state.tabIndex] || null}
            </List>
            <Toast isOpen={state.toast !== ""}>
                <div>{state.toast}</div>
            </Toast>
        </Page>
    )
}

export default LoginPage;
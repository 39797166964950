import React, {createContext, useContext} from "react";
import {FirebaseApp, initializeApp} from "firebase/app";

const FirebaseContext = createContext<FirebaseApp | null>(null);

export const FirebaseAppProvider = ({children}: Props) => {
    const firebaseConfig = {
        apiKey: "AIzaSyDjF-SSu201uxMnZfwbqveAhHnjJgCY40c",
        authDomain: "cba-collector.firebaseapp.com",
        projectId: "cba-collector",
        storageBucket: "cba-collector.appspot.com",
        messagingSenderId: "307492502050",
        appId: "1:307492502050:web:71189a22200a83a9c6a87b"
    };

    const app = initializeApp(firebaseConfig);

    // any child components will be able to use `useUser`, `useDatabaseObjectData`, etc
    return (
        <FirebaseContext.Provider value={app}>
            {children}
        </FirebaseContext.Provider>
    );
};

export const useFirebaseApp = () => {
    const context = useContext(FirebaseContext);
    if (!context) {
        throw new Error('useFirebase must be used within a FirebaseAppProvider');
    }
    return context;
};

type Props = {
    children: JSX.Element
};
import {Button, Row} from "react-onsenui";
import {CollectionPoint, useCollectionPointActions} from "../../../data/firebase/CollectionPointCollection";
import {useT} from "../../translation/Translate";
import {R} from "../../translation/i18n";
import {useCallback} from "react";
import {FeatureStatus} from "../map/StatusText";
import {ButtonModifier} from "../onsenUi/ButtonModifier";
import {UserRoles, useUserRole} from "../../../data/firebase/UserCollection";
import {useNavigate} from "react-router-dom";
import {useUserId} from "../../../data/firebase/provider/AuthProvider";
import {ResultStatus} from "../../../data/firebase/Database";
import {useTruckOfCurrentUser} from "../../../data/firebase/TruckCollection";
import {Truck} from "../../../data/firebase/converter/TruckConverter";

type Props = { collectionPoint: CollectionPoint, modifier?: ButtonModifier | undefined, details: boolean }

const CollectionPointStatusActionButton = ({collectionPoint, modifier, details = false}: Props) => {
    const t = useT()
    const {setStatus, assign, setTruckAndStatus} = useCollectionPointActions()
    const userId = useUserId()
    const {status: userRoleStatus, data: userRole} = useUserRole()
    const {status: trucksStatus, data: trucksData} = useTruckOfCurrentUser();
    const navigate = useNavigate();

    const getActions = useCallback(({head}: UserRoles, truck: Truck | null) => {
        const isAssigned = (feature: CollectionPoint, userId: string) =>
            feature.properties.preparer
            && feature.properties.preparer.includes(userId)

        const status = collectionPoint.properties.status
        const assigned = isAssigned(collectionPoint, userId)
        let actions = []

        if (status === FeatureStatus.Planned) {
            actions.push({
                label: R.assign,
                hasAction: true,
                action: () => assign(collectionPoint.id)
            })
        }

        if (status === FeatureStatus.AssignedForPrepare && assigned) {
            actions.push({
                label: R.prepared,
                hasAction: true,
                action: () => setStatus(collectionPoint.id, FeatureStatus.Prepared)
            })
        }

        if (status === FeatureStatus.Prepared && head) {
            actions.push({
                label: R.ready,
                hasAction: true,
                action: () => setStatus(collectionPoint.id, FeatureStatus.Ready)
            })
        }

        if (status === FeatureStatus.Ready && truck) {
            actions.push({
                label: R.picked,
                hasAction: true,
                action: () => setTruckAndStatus(collectionPoint.id, truck.id, FeatureStatus.Picked)
            })
        }

        if (status === FeatureStatus.AssignedForPick && truck && truck.id === collectionPoint.properties.truck) {
            actions.push({
                label: R.picked,
                hasAction: true,
                action: () => setStatus(collectionPoint.id, FeatureStatus.Picked)
            })
        }

        if (details && (
            head || truck || assigned
        )) {
            actions.push({
                label: R.details,
                action: async () => navigate("/collection-point/" + collectionPoint.id)
            })
        }

        return actions
    }, [collectionPoint, userId, details, assign, setStatus, setTruckAndStatus, navigate])

    if (userRoleStatus !== ResultStatus.Success || trucksStatus !== ResultStatus.Success) {
        return null
    }

    const actions = getActions(userRole, trucksData)

    return (
        actions ? <Row>
            {actions.map(({label, action}, idx) => (
                <Button
                    key={"col-action-" + idx}
                    modifier={modifier}
                    onClick={action}
                >
                    {t(label)}
                </Button>
            ))}
        </Row> : null
    )
}

export default CollectionPointStatusActionButton
import {BackButton, Button, List, ListHeader, ListItem, Page, Segment, Toolbar} from "react-onsenui";
import {R} from "../translation/i18n";
import {useT} from "../translation/Translate";
import {MapContainer, Marker, useMapEvents} from "react-leaflet";
import OSMLayer from "../feature/map/OSMLayer";
import {LocationCircle} from "../feature/map/LocationCircle";
import {useNavigate} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import AreaLayerGroup from "../feature/area/AreaLayerGroup";
import {LatLng} from "leaflet";
import {GreyIcon} from "../feature/map/Icon";
import {FIRST_PAGE_PATH} from "../router/Router";
import {useUser} from "../../data/firebase/provider/AuthProvider";
import {CollectionPointSize, useCollectionPointActions} from "../../data/firebase/CollectionPointCollection";
import {toGeoPoint} from "../../utils/Geo";

const inBounds = (pos: LatLng) =>
    49.69 < pos.lat
    && pos.lat < 49.74
    && 11.03 < pos.lng
    && pos.lng < 11.12

const NewMarker = ({updatePosition}: { updatePosition: (latLng: LatLng) => void }) => {
    const [center, setCenter] = useState<LatLng | undefined>()
    const [clicked, setClicked] = useState(false)

    useEffect(() => {
        if (center && inBounds(center)) {
            updatePosition(center)
        }
    }, [center, updatePosition])

    useMapEvents({
        click: (e) => {
            setClicked(true)
            setCenter(e.latlng)
        },
        locationfound: (e) => {
            const latlng = e.latlng;
            if (!clicked && inBounds(latlng)) {
                setCenter(latlng)
            }
        }
    })
    return center ? (
        <Marker position={center} icon={GreyIcon}/>
    ) : null
};

const AddPage = () => {
    const t = useT()
    const {add} = useCollectionPointActions();
    const navigate = useNavigate()
    const {data: user} = useUser()
    const [center, setCenter] = useState<LatLng | null>(null)
    const [size, setSize] = useState<CollectionPointSize>(CollectionPointSize.Unknown)

    const savePosition = useCallback(async () => {
        if (!center) return
        await add(toGeoPoint(center), size)
        navigate(FIRST_PAGE_PATH)
    }, [add, center, navigate, size, user])

    const toolbar = () => <Toolbar>
        <div className="left">
            <BackButton onClick={() => navigate(-1)} className={"destructive"}>{t(R.abort)}</BackButton>
        </div>
        <div className="center">{t(R.addCollectionPoint)}</div>
        <div className="right">
            <Button
                disabled={center === null}
                onClick={savePosition}
                modifier={"quiet"}
            >
                {t(R.add)}
            </Button>
        </div>
    </Toolbar>

    return (
        <Page renderToolbar={toolbar}>
            <List renderHeader={() => (
                <ListHeader>{t(R.location)}</ListHeader>
            )}>
                <ListItem key="item-detail-map">
                    <MapContainer
                        center={[49.722, 11.078]}
                        zoom={13}
                        scrollWheelZoom={true}
                        style={{width: "100%", minHeight: "500px"}}
                    >
                        <OSMLayer/>
                        <AreaLayerGroup disabled={true}/>
                        <LocationCircle flyTo={inBounds}/>
                        <NewMarker updatePosition={setCenter}/>
                    </MapContainer>
                </ListItem>
                <ListItem key="add-item-size">
                    <Segment index={size - 1} style={{width: "100%"}}>
                        <Button onClick={() => setSize(CollectionPointSize.Small)}>{t(R.small)}</Button>
                        <Button onClick={() => setSize(CollectionPointSize.Medium)}>{t(R.medium)}</Button>
                        <Button onClick={() => setSize(CollectionPointSize.Large)}>{t(R.large)}</Button>
                    </Segment>
                </ListItem>
            </List>
        </Page>
    )
}

export default AddPage